import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import styled from 'styled-components';

import { ThemedSegment, ColoredIcon } from 'components';

const StyledLink = styled(({ active, ...props }) => <Link {...props} />)`
  i,
  p {
    color: ${({ active, theme: { color } }) =>
      active ? color.tileFgActive : color.tileFg};
  }

  &:hover i,
  &:hover p {
    color: ${({ theme: { color } }) => color.tileFgHover} !important;
  }
`;

const TileTitle = styled.p`
  margin-top: 5px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const TileContent = ({
  path,
  icon,
  iconColor,
  label,
  rotated,
  color,
  scroll,
  location,
  active,
}) => {
  const TileIcon = icon && (
    <ColoredIcon
      icon={icon}
      iconColor={iconColor}
      size={scroll ? undefined : 'huge'}
      rotated={rotated ? rotated : undefined}
    />
  );

  const isActive = path ? path === location.pathname : active;

  const renderContent = (
    <ThemedSegment
      textAlign="center"
      segmentColor={color}
      inline={scroll}
      active={isActive}
    >
      {!scroll && TileIcon}
      <TileTitle>
        {scroll && TileIcon}
        {label}
      </TileTitle>
    </ThemedSegment>
  );

  return path ? (
    <StyledLink to={path} active={isActive}>
      {renderContent}
    </StyledLink>
  ) : (
    renderContent
  );
};

export default withRouter(TileContent);
