import React, { useEffect, useState } from 'react';

import { useApi } from 'api/useApi';
import trackingApi from 'api/tracking/trackingApi';
import { Spinner } from 'components';

import TrackingByAddress from './TrackingByAddress';

const TrackingByAddressContainer = ({ area }) => {
  const [loading, setLoading] = useState(false);
  const [address, setAddress] = useState({
    number: undefined,
    complement: undefined,
    street: undefined,
    city: undefined,
  });
  const [getArgs] = useState([area.key.toUpperCase()]);
  const [trackArgs, setTrackArgs] = useState([]);

  const [fetchedAddresses, pendingFetch] = useApi(
    trackingApi.getAddresses,
    getArgs
  );
  const [fetchedDate, pendingDate] = useApi(trackingApi.track, trackArgs);

  useEffect(() => {
    if (address.number) {
      setTrackArgs([address]);
    }
  }, [address]);

  const handleChangeMunicipality = (e, data) => {
    setAddress({
      ...address,
      city: data.value,
      street: undefined,
      number: undefined,
    });
  };

  const handleChangeStreet = (e, data) => {
    setAddress({ ...address, street: data.value, number: undefined });
  };

  const handleChangeNumber = (e, data) => {
    const postdata = fetchedAddresses.addresses.find(
      a =>
        a.number === data.value &&
        a.street === address.street &&
        a.city === address.city
    );
    const newAddress = {
      ...address,
      number: data.value,
      complement: postdata.complement,
    };

    setAddress(newAddress);
    setLoading(true);
  };

  return pendingFetch ? (
    <Spinner />
  ) : (
    <TrackingByAddress
      addresses={fetchedAddresses.addresses}
      address={address}
      date={pendingDate || !fetchedDate ? undefined : fetchedDate.dateTimes[0]}
      ready={loading || pendingDate}
      handleChangeMunicipality={handleChangeMunicipality}
      handleChangeStreet={handleChangeStreet}
      handleChangeNumber={handleChangeNumber}
    />
  );
};

export default TrackingByAddressContainer;
