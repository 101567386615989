import React, { useState, useEffect, useRef } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import Utils from 'core/utils/utils';
import InputFileImage from './InputFileImage';
import ToastFileError from '../components/ToastFileError';

const InputFileImageContainer = ({ fis, onChange, formData, disabled }) => {
  const { t } = useTranslation();
  const [imagePreviewUrl, setImagePreviewUrl] = useState(undefined);

  const inputEl = useRef(null);

  const isImageStored =
    formData && formData[fis.key] && !formData[`${fis.key}_fileName`];

  useEffect(() => {
    if (isImageStored) setImagePreviewUrl(formData[fis.key]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleImageChange = (e, field) => {
    e.preventDefault();

    let reader = new FileReader();
    const file = e.target.files[0];

    if (file) {
      const res = Utils.fileValidated(file, true);

      if (res && res.result) {
        reader.readAsDataURL(file);
        onChange(e, field);
      } else {
        toast.error(<ToastFileError invalidType={res.invalidType} />);
      }
    }

    reader.onloadend = () => {
      setImagePreviewUrl(reader.result);
    };
  };

  const handleImageReset = () => {
    setImagePreviewUrl(undefined);
    inputEl.current.value = '';
    onChange(inputEl.current);
  };

  const handleSelectImage = e => {
    e.preventDefault();
    inputEl.current.click();
  };

  // XOR operation for toggle the required validation
  const toggleRequired =
    fis.required &&
    (!!inputEl.current && !!inputEl.current.value) === !!imagePreviewUrl;

  return (
    <InputFileImage
      t={t}
      fis={fis}
      formData={formData}
      imagePreviewUrl={imagePreviewUrl}
      inputEl={inputEl}
      isImageStored={isImageStored}
      toggleRequired={toggleRequired}
      disabled={disabled}
      handleImageChange={handleImageChange}
      handleImageReset={handleImageReset}
      handleSelectImage={handleSelectImage}
    />
  );
};

export default InputFileImageContainer;
