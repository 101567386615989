import React from 'react';
import { Dropdown } from 'semantic-ui-react';
import styled from 'styled-components';

const ThemedDropdown = styled(({ dropdownRef, clearable, ...props }) => (
  <Dropdown ref={dropdownRef} fluid scrolling {...props} />
))`
  &&& {
    cursor: pointer;
    min-height: 2.71428571em;
    display: block;
    padding: 0.75em 0.75em 0.35em 1em;
    color: rgba(0, 0, 0, 0.87);
    box-shadow: none;
    border: 1px solid rgba(34, 36, 38, 0.15);
    border-radius: 0.28571429rem;
    -webkit-transition: width 0.1s ease, -webkit-box-shadow 0.1s ease;
    -webkit-transition: box-shadow 0.1s ease, width 0.1s ease;
    transition: box-shadow 0.1s ease, width 0.1s ease;

    background-color: ${({ theme: { color } }) => color.dropdownBg};
    color: ${({ theme: { color } }) => color.fg} !important;

    &.ui.active.dropdown {
      border-color: ${({ theme: { color } }) => color.primary};
    }

    > .text {
      position: absolute;
      line-height: 1.1em;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: calc(100% - 2.6em);
    }

    input.search {
      color: ${({ theme: { color } }) => color.fg} !important;
    }

    &.ui.dropdown {
      > .dropdown.icon {
        margin-top: 0.2108em;

        &:before {
          content: ${({ clearable }) => (clearable ? `'\\F00D'` : `'\\F0D7'`)};
          color: ${({ clearable, theme: { color } }) =>
            clearable ? color.inputFg : ''};
          opacity: ${({ clearable }) => (clearable ? '0.5' : '')};
          transition: ${({ clearable }) =>
            clearable ? 'opacity .3s ease' : ''};
        }

        &:hover:before {
          opacity: ${({ clearable }) => (clearable ? '1' : '')};
        }
      }

      .visible.menu .menu {
        display: block;
        max-height: unset;
        position: static;
        border: none;
        box-shadow: none !important;
        border-radius: 0 !important;
        margin: 0 !important;
        min-width: 100% !important;
        width: auto !important;
        border-top: none;

        > .item {
          margin-left: 1em;
        }
      }

      .visible.menu .menu:not(:first-child) {
        border-top: 1px solid
          ${({ theme: { color } }) => color.dropdownMenuBorderColor};
      }
    }

    &.ui.multiple.dropdown {
      padding-top: 0.3em;
      padding-bottom: 0.22619048em;
      padding-left: 0.35714286em;

      > .dropdown.icon {
        margin-top: 0.7361em;
      }
    }

    .menu {
      background-color: ${({ theme: { color } }) => color.dropdownBg};
      box-shadow: ${({ theme: { color } }) => color.boxShadowDropdown},
        ${({ theme: { color } }) => color.boxShadowDropdown};

      .item {
        min-height: 2.543em;
        background-color: ${({ theme: { color } }) => color.dropdownBg};
        color: ${({ theme: { color } }) => color.fg};
        border-color: ${({ theme: { color } }) =>
          color.dropdownMenuItemBorderColor};

        &:hover {
          background-color: ${({ theme: { color } }) =>
            color.dropdownItemBgHover};
        }
      }

      .active.item {
        box-shadow: inset 2px 0 0 ${({ theme: { color } }) => color.primary};
        background-color: ${({ theme: { color } }) =>
          color.sidebarMenuItemBgActive};

        &:hover {
          background-color: ${({ theme: { color } }) =>
            color.dropdownItemBgHover};
        }
      }

      .header {
        font-style: italic;
      }
    }
  }
`;

export default ThemedDropdown;
