import moment from 'moment';
import _ from 'lodash';

import {
  COLOR,
  DEFAULT_OPTION,
  FIELD_TYPE,
  VALID_IMAGE_EXTENSION,
  FILE_SIZE_LIMIT,
  INVALID_FILE_TYPE,
} from './constant';
import { typeMapper } from './mapper';
import { getTemplateContents } from './form';

class Utils {
  validateEmail = email => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };

  validatePassword = password => password && password.length > 5;

  toMomentObject = (dateString, customFormat) => {
    const DISPLAY_FORMAT = 'L';
    const ISO_FORMAT = 'DD/MM/YYYY';

    const dateFormats = customFormat
      ? [customFormat, DISPLAY_FORMAT, ISO_FORMAT]
      : [DISPLAY_FORMAT, ISO_FORMAT];

    const date = moment(dateString, dateFormats, true);
    return date.isValid() ? date.hour(12) : null;
  };

  // transform 'myText' to 'MyText'
  toPascalCase = text =>
    text && typeof text === 'string'
      ? text.charAt(0).toUpperCase() + text.slice(1)
      : '';

  // transform 'text IS text' to 'Text is text'
  toSentenceCase = text =>
    text && typeof text === 'string'
      ? text.charAt(0).toUpperCase() + text.slice(1).toLowerCase()
      : '';

  // Only used for exceptionUser, exceptionURole
  filterDataByExceptions = (array, exceptionUser, exceptionURole) => {
    return array.filter(a => {
      if (a.exceptionID !== null) {
        if (exceptionURole && !_.isEmpty(exceptionURole)) {
          const except = _.find(exceptionURole, ['exceptionID', a.exceptionID]);

          if (except) {
            return except.include;
          }
        }
        if (exceptionUser && !_.isEmpty(exceptionUser)) {
          const except = _.find(exceptionUser, ['exceptionID', a.exceptionID]);

          if (except) {
            return except.include;
          }
        }
      }
      return true;
    });
  };

  getColumnsDefaultData = row => [
    {
      fieldID: 1,
      value: row.rowID + '',
    },
    { fieldID: 2, value: row.status && row.status.name },
    { fieldID: 3, value: row.lastEdit.substr(0, 19).replace('T', ' ') },
    { fieldID: 4, value: row.creator },
  ];

  // check if object should be shown in provided area
  showInArea = ({ areaOnly, areas }, area) => {
    return (
      (!areaOnly && !area) || (area && areas && _.indexOf(areas, area.key) >= 0)
    );
  };

  // read claims to determine user role
  getRole = user => {
    let role;

    if (
      user &&
      user.username &&
      user.claims &&
      user.claims.filter(
        c =>
          c.type ===
          'http://schemas.microsoft.com/ws/2008/06/identity/claims/role'
      ).length > 0
    ) {
      role = user.claims.filter(
        c =>
          c.type ===
          `http://schemas.microsoft.com/ws/2008/06/identity/claims/role`
      )[0].value;
    }

    return role;
  };

  // read claims to determine access permission
  canAccess = (user, type) => {
    let granted = false;

    if (
      user &&
      user.username &&
      user.claims &&
      user.claims.filter(c => c.type.indexOf('access/') === 0)
    ) {
      granted =
        user.claims.filter(c => c.type === `access/${type}` && c.value === '1')
          .length > 0
          ? true
          : false;
    }

    return granted;
  };

  // read claims to determine perform permission
  canPerform = (user, type) => {
    let granted = false;

    if (
      user &&
      user.username &&
      user.claims &&
      user.claims.filter(c => c.type.indexOf('perform/') === 0)
    ) {
      granted =
        user.claims.filter(c => c.type === `perform/${type}` && c.value === '1')
          .length > 0
          ? true
          : false;
    }

    return granted;
  };

  // query stringify
  stringify = obj => {
    const str = [];
    for (const p in obj)
      if (obj.hasOwnProperty(p)) {
        str.push(`${encodeURIComponent(p)}=${encodeURIComponent(obj[p])}`);
      }
    return str.join('&');
  };

  // sort and move down all empty string to bottom
  sortFieldWithFalsey = (array, field, isAscending = true) =>
    array.sort((a, b) => {
      const posA = a[field];
      const posB = b[field];
      return (
        (posA === '') - (posB === '') || +(+posA > +posB) || -(+posA < +posB)
      );
    });

  // on multiDates change
  getDefaultDateTimePeriod = val => {
    const now = new Date();
    const year = now.getYear() + 1900;
    const month = now.getMonth() + 1;
    const day = now.getDate();

    const monthString = month < 10 ? `0${month}` : month;
    const dayString = day < 10 ? `0${day}` : day;

    const leftValue = val
      ? val.split('|')[0]
      : `${year}-${monthString}-${dayString}T00:00`;
    const rightValue = val
      ? val.split('|')[1]
      : `${year}-${monthString}-${dayString}T23:59`;

    return `${leftValue}|${rightValue}`;
  };

  // map form data to filters
  mapDataToFilters = formData =>
    _.map(formData, (value, property) => ({
      key: property,
      value,
    }));

  // get clickables by status
  getClickablesByStatus = (clickables, status) =>
    clickables.filter(
      c =>
        (!c.fromStatus && !status) ||
        (!c.modal && !c.fromStatus) ||
        c.fromStatus === status
    );

  // inject default option into a list option
  injectDefaultOptionToListOptions = listOption => {
    return [DEFAULT_OPTION, ...listOption];
  };

  // convert
  convertToOptions = (valueArray = []) => {
    return _.map(valueArray, value => ({ key: value, value, text: value }));
  };

  // convert to options and inject default option
  convertToOptionsWithDefault = (valueArray = []) => {
    return this.injectDefaultOptionToListOptions(
      this.convertToOptions(valueArray)
    );
  };

  // check if an object have a property key and its value is not empty
  isValueEmpty = (obj, key) => {
    // Return true if the object doesn't have this key or the value of this object by key is empty. Return false otherwise
    return (
      !obj.hasOwnProperty(key) ||
      obj[key] === undefined ||
      obj[key] === null ||
      obj[key] === ''
    );
  };

  // transform Semantic (e, data) params to Faster { field, value } data
  getFieldValueFromEData = (e, data) => {
    const { name, value } = data ? data : e.target;
    const newValue =
      data && FIELD_TYPE.checkbox === typeMapper(data.type)
        ? data.checked
        : value;

    return { field: name, value: newValue };
  };

  //
  isValidFormData = (form, currentData, key, newValue) => {
    const isValid = !_.some(form, input => {
      if (
        input.isVisible &&
        input.required &&
        ((input.key === key && newValue === '') || // compare with local change because it isn't update in currentData yet
          (input.key !== key &&
            currentData &&
            this.isValueEmpty(currentData, input.key)))
      ) {
        return true;
      }
    });

    return isValid;
  };

  transform = (obj, predicate) =>
    Object.keys(obj).reduce((memo, key) => {
      if (predicate(obj[key], key)) {
        memo[key] = obj[key];
      }
      return memo;
    }, {});

  omit = (obj, items) =>
    this.transform(obj, (value, key) => !items.includes(key));

  // Only used for routing
  decodePath = (encoded, is3D) => {
    var len = encoded.length;
    var index = 0;
    var array = [];
    var lat = 0;
    var lng = 0;
    var ele = 0;

    while (index < len) {
      var b;
      var shift = 0;
      var result = 0;
      do {
        b = encoded.charCodeAt(index++) - 63;
        result |= (b & 0x1f) << shift;
        shift += 5;
      } while (b >= 0x20);
      var deltaLat = result & 1 ? ~(result >> 1) : result >> 1;
      lat += deltaLat;

      shift = 0;
      result = 0;
      do {
        b = encoded.charCodeAt(index++) - 63;
        result |= (b & 0x1f) << shift;
        shift += 5;
      } while (b >= 0x20);
      var deltaLon = result & 1 ? ~(result >> 1) : result >> 1;
      lng += deltaLon;

      if (is3D) {
        // elevation
        shift = 0;
        result = 0;
        do {
          b = encoded.charCodeAt(index++) - 63;
          result |= (b & 0x1f) << shift;
          shift += 5;
        } while (b >= 0x20);
        var deltaEle = result & 1 ? ~(result >> 1) : result >> 1;
        ele += deltaEle;
        array.push([lat * 1e-5, lng * 1e-5, ele / 100]);
      } else array.push([lat * 1e-5, lng * 1e-5]);
    }
    // var end = new Date().getTime();
    // console.log("decoded " + len + " coordinates in " + ((end - start) / 1000) + "s");
    return array;
  };

  columnWidthByColsSpan = (colspan, totalColspan) =>
    colspan ? Math.round((colspan * 16) / totalColspan) : null;

  maxColSpan = colItems => {
    const maxColSpanElt = _.maxBy(colItems, item => item.columnSpan);
    return maxColSpanElt ? maxColSpanElt.columnSpan : 1;
  };

  getRowSpan = (row, fieldCheckVisible) => {
    let totalSpanInRow = 0;
    let elementsInRow = row;

    if (fieldCheckVisible) {
      elementsInRow = fieldCheckVisible.filter(
        s => s.row === row && (s.visible || s.visible === false)
      );
    }

    const colsSorted = [
      ...new Set(elementsInRow.map(item => item.column)),
    ].sort();

    // sum of all max(colSpan) in each column
    colsSorted.forEach(c => {
      const elts = elementsInRow.filter(elt => elt.column === c);
      totalSpanInRow += this.maxColSpan(elts);
    });

    return { totalSpanInRow, elementsInRow, colsSorted };
  };

  getEltKey = _elt => (_elt.sectionID > 0 ? _elt.sectionID : _elt.key);

  //return origin value when it's hexa color (start with #)
  //and lowercase value when it's contains in list of basic color or has rgb format (rgb(...))
  //otherwise return null (use theme color)
  getColor = color => {
    if (color) {
      if (color.startsWith('#')) {
        return color;
      } else {
        const colorLowercase = color.toLowerCase();

        if (colorLowercase.startsWith('rgb') || COLOR[colorLowercase]) {
          return colorLowercase;
        }
      }
    }

    return null;
  };

  // when value of checkbox, tell if box is checked
  getBoxChecked = value => {
    return (
      value === true ||
      value === 'true' ||
      value === 'True' ||
      value === '1' ||
      value === 1
    );
  };

  // reduce bool array using && operator
  reduceAndBoolArray = boolArray => _.reduce(boolArray, (a, b) => a && b);

  // reduce bool array using || operator
  reduceOrBoolArray = boolArray => _.reduce(boolArray, (a, b) => a || b);

  // get sections from templates
  getSectionsFromTemplates = templates =>
    this.getFlatSubArray(templates, [
      t => _.filter(getTemplateContents(t), ct => ct.sectionID >= 0),
    ]);

  // get fields from sections
  getFieldsFromSections = sections =>
    this.getFlatSubArray(sections, [s => s.fields]);

  // get fields from templates
  getFieldsFromTemplates = templates =>
    this.getFlatSubArray(templates, [
      t => _.filter(getTemplateContents(t), ct => ct.sectionID >= 0),
      s => s.fields,
    ]);

  // build a flat array from sub arrays in array
  getFlatSubArray = (array, predicates, compact = true) => {
    // push array into a stream
    let stream = _.chain(array);

    // flat map of sub arrays
    for (var i = 0; i < predicates.length; i++) {
      stream = stream.flatMap(predicates[i]);
    }

    if (compact) {
      // remove all falsey value: null, undefined
      stream = stream.compact();
    }

    // return
    return stream.value();
  };

  // is category then build the format key : 'fieldKey|catKey1'
  buildKeyAutoHideForCategory = (fieldKey, catKey) => fieldKey + '|' + catKey;

  // return file name from url of type .../filename.extension
  getNameFromUrl = url => {
    return this.getFullNameFromUrl(url).split('.')[0];
  };

  // return file name and extension from url of type .../filename.extension
  getFullNameFromUrl = url => {
    return url.split('/').pop();
  };

  splitKeyAutoHideForCategory = composedKey => {
    const keySplited = composedKey.split('|');
    return { fieldKey: keySplited[0], catKey: keySplited[1] };
  };

  fileValidated = (file, isImage = false) => {
    const splitExtension = file.name.split('.');

    if (splitExtension.length <= 1) {
      return {
        result: false,
        invalidType: INVALID_FILE_TYPE.invalidFileExtension,
      };
    } else if (file.size / 1000 / 1000 > FILE_SIZE_LIMIT) {
      // Check file size
      return {
        result: false,
        invalidType: INVALID_FILE_TYPE.invalidSize,
      };
    } else if (isImage) {
      // Check extension of uploaded image
      const extension = splitExtension.pop();

      if (!VALID_IMAGE_EXTENSION[extension]) {
        return {
          result: false,
          invalidType: INVALID_FILE_TYPE.invalidImageExtension,
        };
      }
    }

    return {
      result: true,
      invalidType: null,
    };
  };

  buildUrlNav = (area, module, info) => {
    return area
      ? `/${area.key}/${module.key.toLowerCase()}/${info}`
      : `/${module.key.toLowerCase()}/${info}`;
  };
}

export default new Utils();
