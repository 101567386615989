import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Form, Segment, Label, Dropdown } from 'semantic-ui-react';
import styled from 'styled-components';

import { ThemedSegment } from 'components';
import { FormDataCreators } from 'core/form-data/formDataReducer';
import { FieldSetLabel } from 'scenes/dashboard/styles/StyledComponents';

import PopupTemplate from '../template/PopupTemplate';

const Bold = styled.span`
  font-weight: 700;
  margin: 0 6px;
`;

const roundOptions = [
  {
    key: 1,
    text: 'Round 1',
    value: 1,
  },
  {
    key: 2,
    text: 'Round 2',
    value: 2,
  },
];

const TransportDetails = ({ inputs, message, round, transport, ...other }) => (
  <PopupTemplate {...other}>
    {round && (
      <>
        <Label content="Round Details" />
        <Segment>
          <Form>
            <Form.Field>
              <label>Round</label>
              <Dropdown
                placeholder="Round"
                defaultValue={round.id}
                selection
                options={roundOptions}
              />
            </Form.Field>
          </Form>
        </Segment>
      </>
    )}
    <FieldSetLabel content="Transport Details" />
    <ThemedSegment>
      <div>
        <Bold>Name:</Bold> {transport.name}
      </div>
      <div>
        <Bold>Client:</Bold> {transport.client}
      </div>
      <div>
        <Bold>Address:</Bold> {transport.address}
      </div>
      <div>
        <Bold>Phone:</Bold> {transport.phone}
      </div>
      <div>
        <Bold>Volume:</Bold> {transport.volume}
      </div>
      <div>
        <Bold>Notes:</Bold> {transport.notes}
      </div>
    </ThemedSegment>
  </PopupTemplate>
);

export default connect(
  state => state.formData,
  dispatch => bindActionCreators(FormDataCreators, dispatch)
)(TransportDetails);
