import React from 'react';
import { withTranslation } from 'react-i18next';
import { Comment, Header } from 'semantic-ui-react';
import styled from 'styled-components';

import HistoryStatus from './HistoryStatus';

const FormHeader = styled.h2`
  &&&:first-child {
    margin-top: 0;
  }

  color: ${({ theme: { color } }) => color.fg} !important;
`;

const History = ({ t, histories }) => (
  <Comment.Group minimal>
    <Header as={FormHeader} dividing>
      {t('common|History')}
    </Header>
    {histories.map(h => (
      <HistoryStatus key={h.historyID} t={t} h={h} />
    ))}
  </Comment.Group>
);

export default withTranslation()(History);
