import React from 'react';
import { toast } from 'react-toastify';
import _ from 'lodash';

import { ToastMessage } from 'components';

const signalR = require('@aspnet/signalr');

const buildConnection = (hubName, token, channel = undefined) => {
  return new signalR.HubConnectionBuilder()
    .withUrl(`/api/${hubName}${channel ? `?channel=${channel}` : ''}`, {
      accessTokenFactory: () => token,
    })
    .build();
};

const registerHandlers = (connection, handlers) => {
  _.forEach(handlers, handler => {
    connection.on(handler.name, handler.callback);
  });
};

const configureConnection = hubConnection => {
  hubConnection.onclose(message => {
    if (message) {
      toast.error(
        <ToastMessage error message={'Internet connection has been lost'} />,
        { autoClose: false }
      );
      //toast.warn(<ToastMessage warning message={'Please refresh the page'} />, {
      //  autoClose: false,
      //});
    }
  });
};

const openConnection = hubConnection => {
  hubConnection
    .start()
    .catch(_err => console.log('Error while establishing connection'));
};

const baseHub = function(hubName, token, channel = undefined) {
  // build connection
  this.connection = buildConnection(hubName, token, channel);

  // define lifetime handlers
  configureConnection(this.connection);

  // register event handlers
  const handlers = [];

  this.addHandler = (name, callback) => {
    handlers.push({ name, callback });
  };

  // return connection
  this.getConnection = () => {
    registerHandlers(this.connection, handlers);
    openConnection(this.connection);

    return this.connection;
  };
};

export default baseHub;
