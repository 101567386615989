import React, { useCallback, useEffect, useRef, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';

import { getCurrentUser } from 'core/auth/authReducer';
import { getTemplates } from 'core/template/templateReducer';
import { useApi } from 'api/useApi';
import { useHub } from 'api/useHub';
import tableDataApi from 'api/tableData/tableDataApi';
import tableHub from 'api/table/tableHub';
import { Spinner } from 'components';

import TableData from './TableData';

const filterTableColumns = table => ({
  ...table,
  columns: _.filter(table.columns, col => col.position > 0),
});

const TableDataContainer = props => {
  const {
    tableKey,
    table,
    token,
    ids,
    filters,
    selectedFilters,
    dataType,
    dataKey,
    documentID,
    activeItem,
    setActiveItem,
  } = props;

  const canRefetch = useRef(false);

  const initializecriteria = () => {
    const initCrit = { searchByInput: {} };

    if (filters && filters.length > 0) {
      _.forOwn(filters, value => {
        _.forOwn(value, (val, key) => {
          initCrit.searchByInput[key] = val;
        });
      });
    }

    if (selectedFilters && selectedFilters.length > 0) {
      _.forEach(selectedFilters, item => {
        initCrit.searchByInput[item.key] = item.value;
      });
    }

    return initCrit;
  };

  const [loading, setLoading] = useState(false);
  const [activeColumn, setActiveColumn] = useState();
  const [direction, setDirection] = useState();
  const [criteria] = useState(initializecriteria());
  const [query, setQuery] = useState(
    tableDataApi.buildQuery(tableKey, table.type, criteria)
  );
  const [args, setArgs] = useState([
    query,
    ids,
    table,
    dataType,
    dataKey,
    documentID,
  ]);

  const reFetch = useCallback(() => {
    setQuery(query);
    setArgs([query, ids, table, dataType, dataKey, documentID]);
  }, [query, ids, table, dataType, dataKey, documentID]);

  const [fetchedData, pendingData] = useApi(tableDataApi.getMany, args, {
    tableData: undefined,
  });
  useHub(tableHub, token, reFetch);

  useEffect(() => {
    if (canRefetch.current) {
      reFetch();
    } else {
      canRefetch.current = true;
    }
  }, [reFetch]);

  useEffect(() => {
    if (!pendingData) {
      setLoading(false);
    }
  }, [fetchedData, pendingData]);

  const handleRowClick = (item, actionable) => {
    setActiveItem([item, actionable]);
  };

  // Event manipulation
  const handleSort = clickedColumn => () => {
    if (activeColumn !== clickedColumn) {
      criteria.orderByField = clickedColumn;
      criteria.orderByFieldDesc = undefined;

      setQuery(tableDataApi.buildQuery(tableKey, table.type, criteria));
      setDirection('ascending');
      setActiveColumn(clickedColumn);
      setLoading(true);

      return;
    }

    const isAsc = direction === 'ascending';
    const newDirection = isAsc ? 'descending' : 'ascending';
    criteria.orderByField = isAsc ? undefined : clickedColumn;
    criteria.orderByFieldDesc = isAsc ? clickedColumn : undefined;

    setQuery(tableDataApi.buildQuery(tableKey, table.type, criteria));
    setDirection(newDirection);
    setLoading(true);
  };

  const handlePageChange = (e, { activePage }) => {
    criteria.indexPage = activePage;

    setQuery(tableDataApi.buildQuery(tableKey, table.type, criteria));
    setLoading(true);
    setActiveItem([]);
  };

  const handleSearchAllChange = (e, column) => {
    criteria.indexPage = 1;
    criteria.searchAllByValue = column.value === '' ? undefined : column.value;

    setQuery(tableDataApi.buildQuery(tableKey, table.type, criteria));
    setLoading(true);
  };

  const handleSearchFieldChange = (e, column, preventSearch) => {
    criteria.indexPage = 1;

    if (!preventSearch) {
      criteria.searchByInput[column.key] =
        column.value === '' ? undefined : column.value;

      setQuery(tableDataApi.buildQuery(tableKey, table.type, criteria));
      setLoading(true);
    }
  };

  return pendingData ? (
    <Spinner />
  ) : (
    <TableData
      {...props}
      table={filterTableColumns(table)}
      data={fetchedData.tableData}
      activeItem={activeItem}
      activeColumn={activeColumn}
      direction={direction}
      loading={loading}
      handleSearchAllChange={handleSearchAllChange}
      handleSort={handleSort}
      handleSearchFieldChange={handleSearchFieldChange}
      handlePageChange={handlePageChange}
      handleRowClick={handleRowClick}
    />
  );
};

const mapStateToProps = state => {
  const { token, selectedFilters } = getCurrentUser(state);

  return {
    token,
    selectedFilters,
    templates: getTemplates(state),
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    null
  )(withTranslation()(TableDataContainer))
);
