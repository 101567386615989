import React from 'react';
import { Button } from 'semantic-ui-react';
import styled from 'styled-components';

import ColoredIcon from './ColoredIcon';

const applyTheme = ({ theme, primary, secondary, negative, positive }) => {
  return theme && !primary && !secondary && !negative && !positive;
};

const ThemedButton = styled(
  ({
    children,
    theme,
    color,
    icon,
    iconColor,
    content,
    labelPosition,
    ...props
  }) => {
    const iconPassedAsProp = icon && typeof icon === 'string';
    const iconPassedAsChild = icon && icon === true ? true : undefined;
    const defaultLabelPosition = labelPosition
      ? labelPosition
      : (iconPassedAsChild &&
          !(!content && !(Array.isArray(children) && children.length > 1))) ||
        (iconPassedAsProp && (content || children))
      ? 'left'
      : undefined;
    const hasLabelPosition = defaultLabelPosition ? true : false;

    return (
      <Button
        icon={iconPassedAsChild || iconPassedAsProp || hasLabelPosition}
        labelPosition={defaultLabelPosition}
        {...props}
      >
        {iconPassedAsProp && <ColoredIcon icon={icon} iconColor={iconColor} />}
        {content}
        {children}
      </Button>
    );
  }
)`
    &&& {
      margin-top: 0.2em;
      margin-bottom: 0.2em;

      background-color: ${({ theme, color, ...props }) =>
        color
          ? color
          : applyTheme({ theme, ...props })
          ? theme.color.buttonBg
          : 'none'}!important;
      color: ${({ theme, ...props }) =>
        applyTheme({ theme, ...props })
          ? theme.color.buttonFg
          : 'none'}!important;

      &:hover,
      &:active,
      &:focus {
        background-color: ${({ theme, ...props }) =>
          applyTheme({ theme, ...props })
            ? theme.color.buttonBgHover
            : 'none'}!important;
        color: ${({ theme, ...props }) =>
          applyTheme({ theme, ...props })
            ? theme.color.buttonFgHover
            : 'none'}!important;
      }
    }
  }
`;

export default ThemedButton;
