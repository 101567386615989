import React from 'react';
import styled from 'styled-components';

const FormSectionFieldSet = styled(({ visible, ...props }) => (
  <fieldset {...props} />
))`
  ${({ visible }) => visible === false && 'visibility: hidden'}
  &&&&& {
    border-radius: 5px;
    ${({ theme: { color } }) =>
      color.borderPrincipal &&
      `border-color: ${color.borderPrincipal} !important`};
    border-style: solid;
    border-width: 1px;
    background-color: ${props => props.color};
    margin: -1rem 0 0 0;
  }

  @media only screen and (max-width: 767px) {
    .ui.grid &.ui.stackable.grid {
      margin: -1rem 0 0 0 !important;
    }
  }

  & label {
    color: ${({ theme: { color } }) => color.fg} !important;
  }
`;

export default FormSectionFieldSet;
