import React from 'react';

import Utils from 'core/utils/utils';
import { CONTENT_TYPE } from 'core/utils/constant';

import {
  MainGrid,
  FasterAgenda,
  FormSection,
  History,
  ImagePreview,
  PageSegment,
  TrackingByAddress,
} from 'components';

import Embedded from '../components/Embedded';

const buildPage = (t, pc, area, module, currentUser, props) => {
  if (pc.sectionID >= 0) {
    return pc && pc.visible ? (
      <PageSegment key={pc.key} basic>
        <FormSection section={pc} {...props} />
      </PageSegment>
    ) : (
      <FormSection key={pc.key} section={pc} {...props} />
    );
  } else {
    switch (pc.type) {
      case CONTENT_TYPE.title:
        return <h3>{pc.value}</h3>;
      case CONTENT_TYPE.iframe:
        return <Embedded title={pc.value} url={pc.value} compact />;
      case CONTENT_TYPE.table:
        return (
          <MainGrid
            module={module}
            area={area}
            tableKey={pc.tableKey}
            documentID={props.documentID}
          />
        );
      case CONTENT_TYPE.trackingByAddress:
        return Utils.canPerform(currentUser, 'trackbyaddress') ? (
          <TrackingByAddress area={area} />
        ) : (
          undefined
        );
      case CONTENT_TYPE.agenda:
        return (
          <FasterAgenda module={module} area={area} tableKey={pc.tableKey} />
        );
      case CONTENT_TYPE.history:
        return props.histories ? (
          <PageSegment key={pc.pageContentID}>
            <History histories={props.histories} />
          </PageSegment>
        ) : (
          undefined
        );
      case CONTENT_TYPE.image:
        return (
          <ImagePreview
            imagePreviewUrl={pc.value}
            isImageStored
            imageStoredUrl={pc.value}
          />
        );
      default:
        // default 'text'
        return <p>{pc.value}</p>;
    }
  }
};

/*
 * key is pc.pageContentID or section.key
 * because pc.pageContentID might be duplicate with section.sectionID
 */
const getContentKey = _ct =>
  _ct.pageContentID > 0 ? _ct.pageContentID : _ct.key;

export { buildPage, getContentKey };
