import React from 'react';
import { StyledPagination } from 'scenes/dashboard/styles/StyledComponents';
import { Center } from 'components';
import { MainGridTotalForms } from './MainGridTotalItems';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';

const Footer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  color: ${({ theme: { color } }) => color.tableFg};
`;

const MainGridTableFooter = ({
  t,
  totalItems,
  totalPages,
  activePage,
  handlePageChange,
  loading,
}) => (
  <Footer>
    <div>
      <MainGridTotalForms t={t} totalItems={totalItems} loading={loading} />
    </div>
    {!loading && (
      <Center>
        <StyledPagination
          activePage={activePage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
          firstItem={null}
          lastItem={null}
          prevItem={null}
          nextItem={null}
        />
      </Center>
    )}
  </Footer>
);

export default withTranslation()(MainGridTableFooter);
