import React from 'react';
import styled from 'styled-components';

import StyledInput from '../../theme/StyledInput';
import getShadowByState from './helpers/getShadowByState';

const TrackedInput = styled(({ unchanged, saved, value, ...props }) => (
  <StyledInput value={value || (value === 0 ? 0 : '')} {...props} />
))`
  &&&&& input {
    box-shadow: ${({ saved, unchanged }) => getShadowByState(saved, unchanged)};
  }
`;

export default TrackedInput;
