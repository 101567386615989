import React from 'react';

import ImagePreview from 'components/image/ImagePreview';

import InputFileButton from '../components/InputFileButton';
import { InputFileImageHidden } from '../components/InputFileHidden';

const InputFileImage = ({
  t,
  fis,
  formData,
  imagePreviewUrl,
  inputEl,
  isImageStored,
  toggleRequired,
  disabled,
  handleSelectImage,
  handleImageReset,
  handleImageChange,
}) => (
  <>
    <label>{fis.name}</label>
    <InputFileButton
      content={t('common|Upload')}
      icon="upload"
      disabled={disabled}
      onClick={handleSelectImage}
    />
    {imagePreviewUrl && (
      <InputFileButton
        content={t('common|Remove')}
        icon="delete"
        disabled={disabled}
        onClick={handleImageReset}
      />
    )}
    <InputFileImageHidden
      ref={inputEl}
      name={fis.key}
      onChange={handleImageChange}
      required={toggleRequired}
    />
    <ImagePreview
      imagePreviewUrl={imagePreviewUrl}
      isImageStored={isImageStored}
      content={fis.name}
      imageStoredUrl={formData[fis.key]}
    />
  </>
);

export default InputFileImage;
