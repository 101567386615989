import React from 'react';
import { Icon, Label, Segment, Button } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';
import styled from 'styled-components';

import { ThemedSegment } from 'components';
import TilesPanel from '../tiles/TilesPanel';

const BodySegment = styled(Segment)`
  &&& {
    padding: 0;
    margin: 0.5rem 0 0 0;

    > .ui.segment:not(:first-child) {
      margin-top: 0.5rem;
      margin-bottom: 1rem;
    }
  }
`;

const StyledLabel = styled(Label).attrs({ basic: true })`
  &&& {
    background-color: ${({ theme: { color } }) => color.accentBg};
    color: ${({ theme: { color } }) => color.primary};
    border-color: ${({ theme: { color } }) => color.primary};
  }
`;

const styleEmbedding = {
  padding: '0',
  overflow: 'hidden',
};

const BackButton = styled(Button)`
  &&& {
    font-weight: 700;
    margin-top: 1px;
  }
`;

const PageSegment = ({
  t,
  quickaccess,
  icon,
  label,
  tiles,
  embedding,
  routes,
  scroll,
  children,
  basic,
  onBack,
}) => (
  <BodySegment basic>
    {onBack && (
      <BackButton
        basic
        color="blue"
        onClick={onBack}
        size="mini"
        content="Back"
        icon="chevron left"
      />
    )}
    {!quickaccess && (icon && label) && (
      <StyledLabel basic>
        <Icon name={quickaccess ? 'bolt' : icon} />
        {quickaccess ? t('common|Quick access') : t(`common|${label}`)}
      </StyledLabel>
    )}
    {!tiles && children && (
      <ThemedSegment basic={basic} style={embedding ? styleEmbedding : null}>
        {children}
      </ThemedSegment>
    )}
    {tiles && routes && (
      <TilesPanel scroll={quickaccess || scroll} routes={routes} />
    )}
  </BodySegment>
);

export default withTranslation()(PageSegment);
