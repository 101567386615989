import React, { Fragment } from 'react';
import FormField from './FormField';
import styled from 'styled-components';
import _ from 'lodash';

import { TextArea } from 'semantic-ui-react';
import { StyledFormField } from 'components';

import { LIMIT_CHAR } from 'core/utils/constant';

class Textarea extends FormField {
  render() {
    const { field, visible, ...rest } = this.props;

    if (_.isNil(visible)) return <Fragment />;

    return (
      <StyledFormField
        control={TextArea}
        label={field.name}
        name={field.key}
        visible={visible}
        maxLength={LIMIT_CHAR}
        disabled={true}
        {...rest}
      />
    );
  }
}

const FormFieldTextarea = styled(Textarea)`
  & {
    textarea,
    textarea:focus {
      color: ${({ theme: { color } }) => color.fg} !important;
      background-color: ${({ theme: { color } }) => color.inputBg} !important;
    }
    textarea:disabled {
      background-color: ${({ theme: { color } }) =>
        color.inputDisabled} !important;
      opacity: 0.45 !important;
    }
  }
`;

export default FormFieldTextarea;
