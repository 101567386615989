import React from 'react';
import { string, bool } from 'prop-types';
import { Label } from 'semantic-ui-react';
import styled from 'styled-components';

const FieldSetLabel = styled(({ visible, ...props }) => <Label {...props} />)`
  &&& {
    margin-left: 0;
    ${({ visible }) => visible === false && 'visibility: hidden'}
  }

  color: ${({ theme: { color } }) => color.buttonFg} !important;
  background-color: ${({ theme: { color } }) => color.labelBoxBg} !important;
`;

const FormSectionHeader = ({ name, visible }) => (
  <FieldSetLabel size="large" visible={visible}>
    {name}
  </FieldSetLabel>
);

FormSectionHeader.propTypes = {
  name: string.isRequired,
  visible: bool.isRequired,
};

export default FormSectionHeader;
