import React from 'react';
import { Table } from 'semantic-ui-react';
import _ from 'lodash';
import { Spinner } from 'components';
import { withTranslation } from 'react-i18next';
import MainGridTableRowData from './MainGridTableRowData';

const showRows = (
  rows,
  table,
  activeItem,
  handleRowClick,
  area,
  module,
  history,
  admin
) =>
  rows.map(r => (
    <MainGridTableRowData
      key={r.key}
      r={r}
      table={table}
      activeItem={activeItem}
      handleRowClick={handleRowClick}
      area={area}
      module={module}
      history={history}
      admin={admin}
    />
  ));

const showSingleRow = (colSpanLength, content) => (
  <Table.Row>
    <Table.Cell colSpan={colSpanLength} content={content} />
  </Table.Row>
);

const MainGridTableRow = ({
  t,
  rows,
  table,
  activeItem,
  colSpanLength,
  handleRowClick,
  loading,
  area,
  module,
  history,
  admin,
}) => {
  if (loading === undefined) {
    loading = true;
  }

  if (!table || loading) {
    return showSingleRow(colSpanLength, <Spinner />);
  }

  if (!loading && _.isEmpty(rows)) {
    return showSingleRow(colSpanLength, t('No records found'));
  }

  return showRows(
    rows,
    table,
    activeItem,
    handleRowClick,
    area,
    module,
    history,
    // type,
    // clientLayoutName,
    admin
  );
};

export default withTranslation()(MainGridTableRow);
