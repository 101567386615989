import React from 'react';
import { Loader, Segment } from 'semantic-ui-react';

const Spinner = () => (
  <Segment basic padded="very">
    <Loader active inline="centered" size="large" />
  </Segment>
);

export default Spinner;
