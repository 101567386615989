import { incrRegEx } from 'incr-regex-package';
import _ from 'lodash';

import { FIELD_TYPE } from 'core/utils/constant';
import { typeMapper } from './mapper';

class RegexUtils {
  updateFormWithMatchedPattern = (e, field, pattern, updateFormData) => {
    if (
      !_.includes(
        [FIELD_TYPE.checkbox, FIELD_TYPE.file],
        typeMapper(field.type)
      )
    ) {
      const matchedValueByPattern = this.matchInputPattern(
        field.value,
        pattern
      );

      if (matchedValueByPattern !== undefined) {
        field.value = matchedValueByPattern; // update value with matched pattern
        updateFormData(e, field);
      }
    } else {
      updateFormData(e, field);
    }
  };

  matchInputPattern = (value, pattern) => {
    let res = value;

    if (pattern) {
      // have pattern then format the value follow this pattern
      const rx = incrRegEx(pattern); // create inc-reg-expr with current pattern
      const matchedResult = rx.matchStr(value);
      if (matchedResult[0]) {
        res = rx.inputStr(); // update result with matched value

        const minChars = rx.minChars();
        if (!minChars.startsWith('＿')) {
          // has mandatoryChars
          const indexNextMaskedChar = minChars.indexOf('＿');
          const mandatoryChars =
            indexNextMaskedChar < 0
              ? minChars
              : minChars.substr(0, indexNextMaskedChar);

          res += mandatoryChars; // auto fill with mandatory characters
        }
      } else {
        res = undefined;
      }
    }

    return res;
  };
}

export default new RegexUtils();
