import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Form } from 'semantic-ui-react';

import {
  bgActionCreators,
  getBackground,
} from 'core/background/backgroundReducer';
import { THEME } from 'core/utils/constant';
import Utils from 'core/utils/utils';
import { FasterDropdown, PageSegment, StyledInput } from 'components';

const ThemeSelector = ({ t, bgActionCreators, background }) => {
  const themeOptions = Object.values(THEME).map(value => {
    const key = Utils.toSentenceCase(value);
    return { key, value, text: t(`common|${key}`) };
  });

  const changeTheme = (e, data) => {
    const { value } = data ? data : e.target;
    bgActionCreators.useTheme(value);
  };

  return (
    <PageSegment icon="tint" label="Theme">
      <Form>
        <Form.Group widths="equal">
          <Form.Field>
            <label>{t('common|Background')}</label>
            <FasterDropdown
              options={themeOptions}
              value={background.theme}
              onChange={changeTheme}
            />
          </Form.Field>
          <Form.Field disabled>
            <label>{t('common|Accent')}</label>
            <StyledInput disabled />
          </Form.Field>
        </Form.Group>
      </Form>
    </PageSegment>
  );
};

const mapStateToProps = state => ({
  background: getBackground(state),
});

const mapDispatchToProps = dispatch => ({
  bgActionCreators: bindActionCreators(bgActionCreators, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(ThemeSelector));
