import React from 'react';

import styled from 'styled-components';
import { Placeholder } from 'semantic-ui-react';

const Bold = styled.span`
  font-weight: 700;
  margin: 0 6px;
`;

const StyledPlaceHolder = styled(Placeholder)`
  &&& {
    width: 100%;
    height: 2em;

    background-color: ${({ theme: { color } }) => color.placeholderBg};
    background-image: ${({ theme: { color } }) => color.placeholderImg};

    .line {
      background-color: ${({ theme: { color } }) => color.placeholderBg};
    }
  }
`;

function withPlaceHolder(loading, Component) {
  if (loading === undefined || loading) {
    return (
      <StyledPlaceHolder>
        <Placeholder.Line />
      </StyledPlaceHolder>
    );
  }
  return Component;
}

const MainGridTotalPages = ({ t, activePage, totalPages, loading }) =>
  withPlaceHolder(
    loading,
    <>
      {t('Page')} <Bold>{activePage}</Bold> {t('out of')}{' '}
      <Bold>{totalPages}</Bold>
    </>
  );

const MainGridTotalForms = ({ t, totalItems, loading }) =>
  withPlaceHolder(
    loading,
    <>
      {t('Total of')} <Bold>{totalItems}</Bold> {t('form(s)')}
    </>
  );

export { MainGridTotalPages, MainGridTotalForms };
