import styled, { createGlobalStyle } from 'styled-components';

const hex2rgba = (hex, alpha = 1) => {
  const [r, g, b] = hex.match(/\w\w/g).map(x => parseInt(x, 16));
  return `rgba(${r},${g},${b},${alpha})`;
};

const CalendarPopupWrapper = createGlobalStyle`
    .rbc-overlay  .rbc-event {
      background-color: ${({ theme: { color: themeColor }, color }) =>
        color || themeColor.agendaCellBg};

      &.rbc-selected {
        background-color: ${({ theme: { color: themeColor }, color }) =>
          color || themeColor.agendaCellSelectedBg};
      }

      &.rbc-event-allday {
        border: ${({ theme: { color } }) => color.agendaCellBorder};
        border-color: ${({ theme: { color: themeColor }, color }) =>
          color || themeColor.agendaCellBorderColor};
      }

      .rbc-event-content span {
        color: ${({ theme: { color } }) => color.agendaFgInverted};
      }
    }
`;

const CalendarWrapper = styled.div`
  &&& {
    color: ${({ theme: { color } }) => color.agendaFg};
    height: 670px;

    a,
    span {
      color: ${({ theme: { color } }) => color.agendaButtonFg};
    }

    button {
      color: ${({ theme: { color } }) => color.agendaButtonFg};
      background-color: ${({ theme: { color } }) => color.agendaButtonBg};
      border-color: ${({ theme: { color } }) => color.agendaBorderColor};

      &:hover,
      &:active,
      &:focus {
        color: ${({ theme: { color } }) => color.agendaButtonFgHover};
        background-color: ${({ theme: { color } }) =>
          color.agendaButtonBgHover};
        border-color: ${({ theme: { color } }) => color.agendaBorderColorHover};
      }
    }

    .rbc-agenda-view table.rbc-agenda-table {
      border: ${({ theme: { color } }) => color.agendaTimeSlotBorder};

      td {
        border-left: ${({ theme: { color } }) => color.agendaTimeSlotBorder};
      }
    }

    .rbc-day-bg + .rbc-day-bg {
      border-left: ${({ theme: { color } }) => color.agendaBorder};
    }

    .rbc-day-slot {
      .rbc-time-slot {
        border-top: ${({ theme: { color } }) => color.agendaTimeSlotBorder};
      }

      .rbc-event {
        border: ${({ theme: { color } }) => color.agendaCellBorder};
      }
    }

    .rbc-event {
      background-color: ${({ theme: { color: themeColor }, color }) =>
        color || themeColor.agendaCellBg};

      &.rbc-selected {
        background-color: ${({ theme: { color: themeColor }, color }) =>
          color || themeColor.agendaCellSelectedBg};
      }

      &.rbc-event-allday {
        border: ${({ theme: { color } }) => color.agendaCellBorder};
        border-color: ${({ theme: { color: themeColor }, color }) =>
          color || themeColor.agendaCellBorderColor};
      }

      .rbc-event-content span {
        color: ${({ theme: { color } }) => color.agendaFgInverted};
      }
    }

    .rbc-header {
      color: ${({ theme: { color } }) => color.agendaFg};
      border-bottom: ${({ theme: { color } }) => color.agendaBorder};

      + .rbc-header {
        border-left: ${({ theme: { color } }) => color.agendaBorder};
      }
    }

    .rbc-off-range-bg {
      background-color: ${({ theme: { color } }) => color.agendaBgAccent};
    }

    .rbc-time-content {
      border-top: ${({ theme: { color } }) => color.agendaBorderBold};

      > * + * > * {
        border-left: ${({ theme: { color } }) => color.agendaBorder};
      }
    }

    .rbc-time-header-content {
      border-left: ${({ theme: { color } }) => color.agendaBorder};
    }

    .rbc-time-view {
      border: ${({ theme: { color } }) => color.agendaBorder};
    }

    .rbc-timeslot-group {
      border-bottom: ${({ theme: { color } }) => color.agendaBorder};
    }

    .rbc-today {
      background-color: ${({ theme: { color: themeColor }, color }) =>
        color ? hex2rgba(color, 0.2) : themeColor.agendaTodayBg};
    }
  }
`;

export { CalendarWrapper, CalendarPopupWrapper };
