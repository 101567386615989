import React from 'react';
import { withTranslation } from 'react-i18next';
import { Table } from 'semantic-ui-react';

import {
  FilterTableCellInput,
  FilterTableCellInputNumber,
} from './components/cell/FilterTableCellInput';
import FilterTableCellButton from './components/cell/FilterTableCellButton';
import {
  FilterTableCellSelect,
  FilterTableCellOperatorSelect,
} from './components/cell/FilterTableCellSelect';
import { GridFilterTableHeader } from './components/layout/FilterTableHeader';
import FilterTableFooter from './components/layout/FilterTableFooter';
import { ThemedTable } from 'components';
import Utils from 'core/utils/utils';
import styled from 'styled-components';

const ScrollableTable = styled.div`
  &&& {
    max-width: 100%;
    min-height: 350px;
    overflow-x: scroll;
    margin-bottom: 1rem;

    .ui.table {
      background-color: ${({ theme: { color } }) => color.tableBg};
      color: ${({ theme: { color } }) => color.tableFg};
      border-color: ${({ theme: { color } }) => color.tableBorderColor};
    }
  }
`;

const FilterTable = ({
  advanced,
  filters,
  templateOptions,
  sectionOptions,
  columnOptions,
  columns,
  activeFilter,
  handleAddFilter,
  handleRemoveFilter,
  handleRowClick,
  handleChangePosition,
  handleChangeTemplate,
  handleChangeSection,
  handleChangeColumn,
  handleChangeOperator,
  handleChangeValue,
  t,
}) => (
  <ScrollableTable>
    <ThemedTable celled selectable structured>
      <GridFilterTableHeader t={t} advanced={advanced} />
      <Table.Body>
        {filters.length > 0 &&
          Utils.sortFieldWithFalsey(filters, 'columnPosition').map(
            ({
              columnPosition,
              templateId,
              sectionID,
              columnID,
              operator,
              value,
            }) => {
              const keyColumns = [
                templateId,
                sectionID,
                columnID,
                columnPosition,
              ];
              return (
                <Table.Row
                  onFocus={handleRowClick(keyColumns)}
                  active={activeFilter === keyColumns}
                  key={keyColumns}
                >
                  <FilterTableCellInputNumber
                    value={columnPosition}
                    onChange={handleChangePosition}
                  />
                  <FilterTableCellSelect
                    options={templateOptions}
                    value={templateId}
                    onChange={handleChangeTemplate}
                  />
                  <FilterTableCellSelect
                    disabled={!templateId || templateId < 0 ? true : undefined}
                    options={sectionOptions.filter(
                      so => so.temp === templateId
                    )}
                    value={sectionID}
                    onChange={handleChangeSection}
                  />
                  <FilterTableCellSelect
                    disabled={sectionID >= 0 ? undefined : true}
                    options={columnOptions.filter(
                      co => co.temp === templateId && co.sect === sectionID
                    )}
                    value={columnID}
                    onChange={handleChangeColumn}
                  />
                  <FilterTableCellOperatorSelect
                    advanced={advanced}
                    columns={columns}
                    fieldID={columnID}
                    operator={operator}
                    handleChangeOperator={handleChangeOperator}
                  />
                  <FilterTableCellInput
                    t={t}
                    advanced={advanced}
                    columns={columns}
                    fieldID={columnID}
                    operator={operator}
                    value={value}
                    handleChangeValue={handleChangeValue}
                  />
                  <FilterTableCellButton
                    icon="times"
                    onClick={handleRemoveFilter}
                  />
                </Table.Row>
              );
            }
          )}
      </Table.Body>
      <FilterTableFooter
        t={t}
        options={templateOptions}
        handleClickFunc={handleAddFilter}
      />
    </ThemedTable>
  </ScrollableTable>
);

export default withTranslation()(FilterTable);
