import React from 'react';
import {
  Image,
  Sidebar,
  Pagination,
  Label,
  Form,
  Dropdown,
} from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { ColoredIcon } from 'components';

//Form
const StyledTextArea = styled(Form.TextArea)`
  &&& textarea {
  background-color: ${({ theme: { color } }) => color.inputBg};
  color: ${({ theme: { color } }) => color.inputFg};
  border-color: ${({ theme: { color } }) => color.borderColor};

  &:hover {
    background-color: ${({ theme: { color } }) => color.inputBg};
    color: ${({ theme: { color } }) => color.inputFgHover};
  }
`;

//Input Dropdown
const StyledDropdown = styled(Dropdown)`
   &&& {
    background-color: ${({ theme: { color } }) => color.dropdownBg};
    color: ${({ theme: { color } }) => color.fg} !important;

    .menu .item {
      background-color: ${({ theme: { color } }) => color.accentBg};
      color: ${({ theme: { color } }) => color.fg};
      border-color: ${({ theme: { color } }) =>
        color.dropdownMenuItemBorderColor};
  }
`;

//Label
const StyledColorLabel = styled(Label)`
  &&& {
    &&.yellow.label {
      background-color: ${({ theme: { color } }) =>
        color.timelineYellowBg} !important;
      color: ${({ theme: { color } }) => color.timelineFg} !important;
    }
    &&.green.label {
      background-color: ${({ theme: { color } }) =>
        color.timelineGreenBg} !important;
      color: ${({ theme: { color } }) => color.timelineFg} !important;
    }
    &&.red.label {
      background-color: ${({ theme: { color } }) =>
        color.timelineRedBg} !important;
      color: ${({ theme: { color } }) => color.timelineFg} !important;
    }
    &.basic.label {
      background-color: ${({ theme: { color } }) =>
        color.timelineBasicBg} !important;
      color: ${({ theme: { color } }) => color.timelineFg} !important;
    }
    &.blue.label {
      background-color: ${({ theme: { color } }) =>
        color.timelineBlueBg} !important;
      color: ${({ theme: { color } }) => color.timelineFg} !important;
    }
    &&&.basic.label {
      border-color: ${({ theme: { color } }) =>
        color.timelineBorder} !important;
    }
  }
`;

const FieldSetLabel = styled(Label)`
  &&& {
    margin-left: 0;
  }

  color: ${({ theme: { color } }) => color.buttonFg} !important;
  background-color: ${({ theme: { color } }) => color.labelBoxBg} !important;
`;

// Pagination
const StyledPagination = styled(Pagination)`
  &&& {
    a {
      color: ${({ theme: { color } }) => color.tableFg};

      &:hover {
        color: ${({ theme: { color } }) => color.tableFg};
        background-color: ${({ theme: { color } }) => color.tableItemBgHover};
      }
      &.active.item {
        color: ${({ theme: { color } }) => color.tableFg} !important;
        background-color: ${({ theme: { color } }) =>
          color.tableItemBgHover} !important;
      }
    }
    background-color: ${({ theme: { color } }) => color.tableBg};
  }
`;

const FormHeader = styled.h2`
  &&&:first-child {
    margin-top: 0;
  }

  color: ${({ theme: { color } }) => color.fg} !important;
`;

//Image
const StyledImage = styled(({ expandedSidebar, ...props }) => (
  <Image {...props} />
))`
  padding: ${props => (props.expandedSidebar ? '15px' : '1px')};
`;

//Sidebar
const SidebarLink = styled(Link)`
  .ui.menu &.item {
    padding: 1.5em 1em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    box-sizing: border-box;
    color: ${({ theme: { color } }) => color.fg};

    &:hover {
      color: ${({ theme: { color } }) => color.fg};
      background-color: ${({ theme: { color } }) =>
        color.sidebarMenuItemBgHover};
    }
  }

  .ui.vertical.menu &.active.item {
    box-shadow: inset 2px 0 0 ${({ theme: { color } }) => color.primary} !important;
    background-color: ${({ theme: { color } }) =>
      color.sidebarMenuItemBgActive};
    &:hover {
      color: ${({ theme: { color } }) => color.fg};
    }
  }
`;

const SidebarIcon = styled(props => <ColoredIcon {...props} />)`
  .ui.vertical.menu .item > &.icon {
    float: left;
    margin: 0 0.5rem 0 0;
    color: ${({ theme: { color }, iconColor }) =>
      !iconColor && color.iconColor} !important;
  }
`;

// Example of using styled component
const FullHeightSidebar = styled(
  ({ expandedSidebar, visibleSidebar, ...props }) => (
    <Sidebar.Pushable {...props} />
  )
)`
  &.pushable {
    &:not(body) {
      transform: none;

      > .ui.sidebar {
        background-color: ${({ theme: { color } }) => color.accentBg};
        position: fixed;
        -ms-overflow-style: none; /*in IE and Edge*/
        scrollbar-width: none; /*in Firefox*/
      }

      // in Chrome
      > .ui.sidebar::-webkit-scrollbar {
        display: none;
      }

      > .fixed,
      > .pusher:after {
        position: fixed;
      }
    }

    .ui.left.sidebar {
      width: ${props => (props.expandedSidebar ? '150px' : '48px')};
      transition: transform 0.5s ease, width 0.3s ease;

      & ~ .pusher {
        transform: ${props =>
          props.visibleSidebar
            ? props.expandedSidebar
              ? 'translate3d(150px,0,0)'
              : 'translate3d(48px,0,0)'
            : 'none'};
      }
    }

    > .pusher {
      display: flex;
      min-height: 100vh;
      flex-direction: column;
    }

    .ui.left.visible.sidebar {
      box-shadow: ${({ theme: { color } }) => color.sidebarBoxShadow};
    }
  }
`;

const LogoPlaceholder = styled.div`
  height: ${props => (props.expandedSidebar ? '118.5px' : '48px')};
`;

export {
  StyledTextArea,
  StyledDropdown,
  StyledColorLabel,
  FieldSetLabel,
  StyledPagination,
  StyledImage,
  SidebarLink,
  SidebarIcon,
  FullHeightSidebar,
  LogoPlaceholder,
  FormHeader,
};
