import React from 'react';

import { Input } from 'semantic-ui-react';
import styled from 'styled-components';

const StyledInput = styled(({ inputRef, component: Component, ...props }) =>
  Component ? <Component {...props} /> : <Input ref={inputRef} {...props} />
)`
  &&& {
    input {
      background-color: ${({ theme: { color } }) => color.inputBg};
      color: ${({ theme: { color } }) => color.inputFg};
      border-color: ${({ theme: { color } }) => color.borderColor};
      text-overflow: ellipsis;

      &:hover,
      &:focus {
        background-color: ${({ theme: { color } }) => color.inputBg};
        color: ${({ theme: { color } }) => color.inputFgHover};
      }
    }

    .ui.form &.field.disabled {
      opacity: 1;

      > label {
        opacity: 1;
      }
    }

    > :disabled,
    .disabled.input > input {
      background-color: ${({ theme: { color } }) =>
        color.inputDisabled} !important;
      opacity: 1;
    }

    &.action.input > :disabled {
      opacity: 0.45 !important;
    }

    i {
      color: ${({ theme: { color } }) => color.fg};
    }

    input:-webkit-autofill + i.icon {
      color: #000;
    }
  }

  &&&&&& input {
    /*Semantic defaults to width auto, which is too wide on 767 to 1100 px-wide screens*/
    width: 100%;
  }
`;

export default StyledInput;
