import _ from 'lodash';

import BaseApi from 'api/baseApi';
import { SYSTEM_FIELDS, OPERATOR, FIELD_TYPE } from 'core/utils/constant';

const tableDataApi = {
  buildQuery: (tableKey, type, criteria) => {
    const baseUrlArray = ['tabledata'];
    const query = [tableKey];
    query.push('?');

    if (type) {
      baseUrlArray.push(type);
    }

    if (!_.isEmpty(criteria) && !_.isEmpty(criteria.searchByInput)) {
      _.forOwn(criteria.searchByInput, (value, key) => {
        if (!_.isNil(key) && !_.isNil(value)) {
          let qVal = '';

          if (Array.isArray(value)) {
            qVal = `&values=${value.join('|')}`;
          } else {
            qVal = `&values=${value}`;
          }

          const qID = `&fields=${key}`;
          const queryString = `${qID}${qVal}`;

          !_.isNil(criteria.searchByInput[key]) && query.push(queryString);
        }
      });
    }

    criteria.orderByField && query.push('&orderBy=', criteria.orderByField);
    criteria.orderByFieldDesc &&
      query.push('&orderByDesc=', criteria.orderByFieldDesc);
    criteria.searchAllByValue &&
      query.push('&searchAll=', criteria.searchAllByValue);
    criteria.indexPage && query.push('&indexPage=', criteria.indexPage);

    return `${baseUrlArray.join('/')}/${query.join('')}`;
  },

  getMany: (query, ids, table, dataType, dataKey, documentID, params) => {
    if (ids && ids.length > 0) {
      const filter = {
        key: SYSTEM_FIELDS.formid,
        operator: OPERATOR.in,
        value: ids.join('|'),
        type: FIELD_TYPE.number,
      };

      // Post id filter
      return BaseApi.post(query, {
        filter,
        ...params,
      });
    } else if (dataType) {
      // Post table structure of admin to query
      return BaseApi.post(query, {
        dataType,
        dataKey,
        table,
        ...params,
      });
    } else {
      if (documentID) {
        query = `${query}&documentID=${documentID}`;
      }

      // Get table data
      return BaseApi.get(query, params);
    }
  },
};

export default tableDataApi;
