import { Button } from 'semantic-ui-react';
import styled from 'styled-components';

const InputFileButton = styled(Button).attrs(props => ({
  size: 'tiny',
  labelPosition: 'left',
}))`
  &&&& {
    max-width: calc(50% - 0.25em);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    background-color: ${({ theme: { color } }) => color.dropdownBgOther};
    color: ${({ theme: { color } }) => color.dropdownFg};

    &:hover,
    &:active,
    &:focus {
      background-color: ${({ theme: { color } }) => color.buttonBgHover};
      color: ${({ theme: { color } }) => color.dropdownFgHover};
    }

    &:disabled {
      background-color: ${({ theme: { color } }) =>
        color.buttonDisabled} !important;
      opacity: 0.45 !important;
    }
  }
`;

export default InputFileButton;
