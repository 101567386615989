import React from 'react';
import { Comment, Label } from 'semantic-ui-react';
import styled from 'styled-components';
import _ from 'lodash';

import { getDateTimeFormatted, getDateFormatString } from 'core/utils/date';
import { FIELD_TYPE } from 'core/utils/constant';
import { typeMapper } from 'core/utils/mapper';

const StyledLabel = styled(Label)`
  color: ${({ theme: { color } }) => color.buttonFg} !important;
  background-color: ${({ theme: { color } }) => color.labelBoxBg} !important;
`;

const StyledMetadata = styled(Comment.Metadata)`
  color: ${({ theme: { color } }) => color.metadata} !important;
`;

const StyledText = styled(Comment.Text)`
  color: ${({ theme: { color } }) => color.fg} !important;
`;

const HistoryStatus = ({ t, h }) => {
  const dateFormat = getDateFormatString();
  const dt = getDateTimeFormatted(
    h.dateTime.split('.')[0],
    dateFormat,
    'HH:mm'
  );

  const valueBefore = h.valueBefore
    ? _.includes([FIELD_TYPE.datetime], typeMapper(h.fieldType))
      ? getDateTimeFormatted(h.valueBefore, dateFormat, 'HH:mm')
      : _.includes([FIELD_TYPE.date], typeMapper(h.fieldType))
      ? getDateTimeFormatted(h.valueBefore, dateFormat, null) // remove time format to only show date
      : h.valueBefore
    : '';

  const valueAfter = h.valueAfter
    ? _.includes([FIELD_TYPE.datetime], typeMapper(h.fieldType))
      ? getDateTimeFormatted(h.valueAfter, dateFormat, 'HH:mm')
      : _.includes([FIELD_TYPE.date], typeMapper(h.fieldType))
      ? getDateTimeFormatted(h.valueAfter, dateFormat, null) // remove time format to only show date
      : h.valueAfter
    : '';

  const renderHistoryCreation = h.creation ? (
    t('Created document with ID')
  ) : (
    <span>
      {t('Changed')} <b>{h.fieldName}</b> {t('from')} <b>"{valueBefore}"</b>{' '}
      {t('to')}
    </span>
  );

  const renderHistory = (
    <i>
      {renderHistoryCreation}
      <b>{` "${valueAfter}"`}</b>
    </i>
  );

  return (
    <Comment>
      <Comment.Content>
        <StyledLabel size="tiny">{h.editor}</StyledLabel>
        <StyledMetadata>
          <span>{dt}</span>
        </StyledMetadata>
        <StyledText>{renderHistory}</StyledText>
      </Comment.Content>
    </Comment>
  );
};

export default HistoryStatus;
