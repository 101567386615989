import React from 'react';
import styled from 'styled-components';
import { Image, Form, Button } from 'semantic-ui-react';
import PopupInfoPanel from '../../components/info-panel/PopupInfoPanel';
import { StyledInput } from 'components';

const EditPhotoContainer = styled.div`
  font-weight: bold;
  text-align: center;
  margin-top: 10px;
  cursor: pointer;
`;

const FormContainer = styled(Form)`
  height: 280px;
  max-height: 300px;
  overflow-y: scroll;
  padding-right: 10px;
  margin-bottom: 10px;
`;

const EditPhoto = () => (
  <EditPhotoContainer>
    <span>Edit photo</span>
  </EditPhotoContainer>
);

const DriverInfoPanel = ({ driver, edited }) => {
  return (
    <PopupInfoPanel>
      <Image size="medium" src={driver && driver.avatar} circular centered />
      <EditPhoto />
      <FormContainer>
        <Form.Input
          control={StyledInput}
          label="Name"
          defaultValue={driver ? driver.name : ''}
          disabled={!edited}
        />
      </FormContainer>
      {edited && <Button content="Save" primary floated="right" />}
    </PopupInfoPanel>
  );
};

export default DriverInfoPanel;
