import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'moment/locale/fr';

import React from 'react';
import { arrayOf, object } from 'prop-types';
import { Calendar, momentLocalizer, Views } from 'react-big-calendar';
import moment from 'moment';

import i18n from 'config/i18n';
import { LOCALES } from 'core/utils/constant';
import { Spinner } from 'components';

import {
  CalendarWrapper,
  CalendarPopupWrapper,
} from './components/CalendarWrapper';
import Event from './components/Event';
import messagesFrench from './helpers/messagesFrench';

const { DAY, WEEK, MONTH } = Views;

const localizer = momentLocalizer(moment);

const FasterAgenda = ({
  form: { currentDate, currentView, color, events },
  loading,
  handleNavigate,
  handleChangeView,
  handleDoubleClick,
}) => {
  const messages =
    localStorage.getItem('faster-lang') === LOCALES.fr
      ? messagesFrench
      : undefined;

  return (
    <>
      <CalendarWrapper color={color}>
        {!loading && events ? (
          <Calendar
            date={currentDate}
            view={currentView}
            events={events}
            culture={i18n.language}
            localizer={localizer}
            step={15}
            timeslots={8}
            startAccessor="start"
            endAccessor="end"
            messages={messages}
            selectable
            onNavigate={handleNavigate}
            onView={handleChangeView}
            views={[DAY, WEEK, MONTH]}
            onDoubleClickEvent={handleDoubleClick}
            popup
            components={{
              event: Event,
            }}
          />
        ) : (
          <Spinner />
        )}
      </CalendarWrapper>
      <CalendarPopupWrapper color={color} />
    </>
  );
};

FasterAgenda.propTypes = {
  events: arrayOf(object),
};

export default FasterAgenda;
