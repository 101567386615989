import { toast, ToastContainer } from 'react-toastify';
import styled from 'styled-components';

const StyledToast = styled(ToastContainer).attrs({
  suppressClassNameWarning: true,
  closeButton: false,
  position: toast.POSITION.TOP_CENTER,
  autoClose: 2500,
})`
  .Toastify__toast {
    padding: 1em 1.5em;

    &--info,
    &--success,
    &--warning,
    &--error {
      background-color: white;
      color: #767676;
      border: 1px solid rgba(34, 36, 38, 0.15);
      border-radius: 0.28571429rem;
      -webkit-box-shadow: 0px 2px 4px 1px rgba(34, 36, 38, 0.15);
      box-shadow: 0px 2px 4px 1px rgba(34, 36, 38, 0.15);
    }

    .Toastify__toast-body {
      max-width: 100%;
    }

    .Toastify__toast-body p {
      text-overflow: ellipsis;
      max-width: 100%;
      white-space: nowrap;
      overflow: hidden;
    }

    &--info .Toastify__progress-bar {
      background: #00b5ad;
      opacity: 0.7 !important;
    }
    &--success .Toastify__progress-bar {
      background: #21ba45;
      opacity: 0.7 !important;
    }
    &--warning .Toastify__progress-bar {
      background: #fbbd08;
      opacity: 0.7 !important;
    }
    &--error .Toastify__progress-bar {
      background: #db2828;
      opacity: 0.7 !important;
    }
  }
`;

export default StyledToast;
