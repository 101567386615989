import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import FormLayout from './FormLayout';
import {
  FieldsActionCreators,
  FieldsActionOperations,
  getFieldsAction,
} from 'core/fields-action/fieldsActionReducer';
import {
  FormDataCreators,
  getVisibleFormData,
} from 'core/form-data/formDataReducer';

const mapStateToProps = state => ({
  fieldsAction: getFieldsAction(state),
  ...getVisibleFormData(state),
});

const mapDispatchToProps = dispatch => ({
  receiveFieldsAction: fields =>
    dispatch(FieldsActionCreators.receiveFieldsAction(fields)),
  fetchFieldsAction: fields =>
    dispatch(FieldsActionOperations.fetchFieldsAction(fields)),

  updateFormData: (event, field) =>
    dispatch(FormDataCreators.updateFormData(event, field)),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withTranslation()(FormLayout))
);
