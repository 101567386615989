import React from 'react';
import { arrayOf, number, object } from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Grid } from 'semantic-ui-react';
import styled from 'styled-components';

import { CONTENT_TYPE } from 'core/utils/constant';
import { PageSegment } from 'components';

import { buildPage } from '../helpers';

const GridColumn = styled(Grid.Column)`
  @media only screen and (max-width: 767px) {
    .ui.stackable.grid > .row > &.column {
      padding: 0 !important;
    }
  }
`;

const CustomPageGridColumn = ({
  t,
  pcs,
  columnWidth,
  area,
  module,
  currentUser,
  ...props
}) => (
  <GridColumn width={columnWidth}>
    {pcs.map(pc =>
      pc.sectionID ||
      pc.type === CONTENT_TYPE.history ||
      pc.type === CONTENT_TYPE.templateHeader ? (
        buildPage(t, pc, area, module, currentUser, props)
      ) : (
        <PageSegment
          key={pc.pageContentID}
          embedding={pc.type === CONTENT_TYPE.iframe ? true : undefined}
        >
          {buildPage(t, pc, area, module, currentUser, props)}
        </PageSegment>
      )
    )}
  </GridColumn>
);

CustomPageGridColumn.propTypes = {
  pcs: arrayOf(object).isRequired,
  columnWidth: number,
};

CustomPageGridColumn.defaultProps = { columnWidth: undefined };

export default withTranslation()(CustomPageGridColumn);
