import React from 'react';
import { connect } from 'react-redux';
import { Button } from 'semantic-ui-react';

import Utils from 'core/utils/utils';
import { BUTTON_TYPE } from 'core/utils/constant';
import DocumentButton from './documentButtonContainer';

const DocumentButtonGroup = ({
  clickables,
  viewersWarning,
  isValidForm,
  submitFunc,
  clearFunc,
  documentID,
  status,
  formData,
  requiredFields,
  patternFields,
  filterSelection,
  externalPrint,
  printable,
  printed,
  print,
  selectedTemplate,
}) => (
  <>
    {clickables &&
      Utils.getClickablesByStatus(clickables, status)
        .sort(function(a, b) {
          if (a.type > b.type) {
            return -1;
          } else if (a.type < b.type) {
            return 1;
          } else {
            return a.name > b.name ? 1 : -1;
          }
        })
        .map(c =>
          !(externalPrint && [BUTTON_TYPE.print].includes(c.type)) ||
          [BUTTON_TYPE.link].includes(c.type) ? (
            <DocumentButton
              key={c.clickableId}
              popupAction={
                c.type.toLowerCase() === 'link' && !c.modal
                  ? {
                      name: 'Warning',
                      message:
                        'Do you want to cancel? You will be redirected and all your changes will be lost',
                      ok: 'Yes',
                      cancel: 'No',
                    }
                  : c.modal
              }
              viewersWarning={viewersWarning}
              documentID={documentID}
              clickable={c}
              isValidForm={isValidForm}
              submitFunc={submitFunc}
              clearFunc={clearFunc}
              formData={formData}
              requiredFields={requiredFields}
              patternFields={patternFields}
              selectedTemplate={selectedTemplate}
              filterSelection={filterSelection}
            />
          ) : (
            <Button.Group primary key={c.clickableId}>
              <Button
                disabled={
                  !printable || formData === undefined || printed
                    ? true
                    : undefined
                }
                onClick={print}
              >
                {c.name}
              </Button>
            </Button.Group>
          )
        )}
  </>
);

export default connect(
  state => state.settings,
  null
)(DocumentButtonGroup);
