import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Icon, Image, Label } from 'semantic-ui-react';
import styled from 'styled-components';

import Utils from 'core/utils/utils';
import InputFileLabel from 'components/input/file/components/InputFileLabel';

const StyledImage = styled(Image)`
  ${({ size }) =>
    // when no content for label, image is centered in its container
    size ? 'margin-top: 15px; margin-left: 15px;' : 'margin: 0 auto;'}
`;

const ImagePreview = ({
  t,
  imagePreviewUrl,
  isImageStored,
  content,
  imageStoredUrl,
}) => {
  const [loaded, setLoaded] = useState(undefined);
  const [error, setError] = useState(false);

  const handleLoad = () => {
    setLoaded(true);
  };

  const handleError = () => {
    setLoaded(true);
    setError(isImageStored && true);
  };

  if (isImageStored && loaded && error) {
    return (
      <Label basic color="red">
        <Icon name="image outline" />
        {`${t('Image not found')}${
          imageStoredUrl ? ` (${Utils.getFullNameFromUrl(imageStoredUrl)})` : ''
        }`}
      </Label>
    );
  }

  return (
    <>
      {(imagePreviewUrl || isImageStored) && (
        <StyledImage
          src={imagePreviewUrl}
          label={
            content && (
              <InputFileLabel
                isImageStored={isImageStored}
                content={content}
                imageStoredUrl={imageStoredUrl}
              />
            )
          }
          size={content ? 'medium' : undefined}
          onLoad={handleLoad}
          onError={handleError}
        />
      )}
    </>
  );
};

export default withTranslation()(ImagePreview);
