import React from 'react';
import _ from 'lodash';

//import { PrintContainer } from 'components';

import BatchSteps from './components/steps/BatchSteps';
import BatchSearch from './components/search/BatchSearch';
import BatchAction from './components/action/BatchAction';
import BatchPerform from './components/perform/BatchPerform';

const Batch = ({
  t,
  currentUser,
  module,
  batchTemplates,
  step,
  action,
  filters,
  ids,
  edits,
  docs,
  duplicate,
  toggleDuplicate,
  filtered,
  completed,
  selectedBatchFilterId,
  selectedBatchEditId,
  onOpenModalBatch,
  printTemplateKey,
  print,
  moveTo,
  handleChangeBatchTemplate,
  bindReloadColOpts,
  funnel,
  preview,
  selectEdits,
  handleSetFilters,
  handleSetEdits,
  edit,
  complete,
}) => {
  const batchPrint = ids.length > 20 ? onOpenModalBatch : print;

  const batchTempOptions = !_.isEmpty(batchTemplates)
    ? batchTemplates.map(({ batchTemplateId, name }) => ({
        key: `${batchTemplateId}`,
        text: name,
        value: `${batchTemplateId}`,
      }))
    : [];

  // add default value
  if (!_.isEmpty(batchTempOptions)) {
    batchTempOptions.unshift({ key: '', text: '', value: '' });
  }

  const batchTemplateProps = {
    batchTemplates,
    batchTempOptions,
    selectedBatchFilterId,
    handleChangeBatchTemplate,
  };

  return (
    <>
      <BatchSteps t={t} step={step} moveTo={moveTo} />
      {step === 1 && (
        <BatchSearch
          t={t}
          filters={filters}
          setFilters={handleSetFilters}
          bindReloadColOpts={bindReloadColOpts}
          funnel={funnel}
          step={step}
          {...batchTemplateProps}
        />
      )}
      {step === 2 && (
        <BatchAction
          t={t}
          filtered={filtered}
          ids={ids}
          preview={preview}
          step={step}
          currentUser={currentUser}
          selectEdits={selectEdits}
          printTemplateKey={printTemplateKey}
          batchPrint={batchPrint}
        />
      )}
      {step === 3 && (
        <BatchPerform
          t={t}
          module={module}
          action={action}
          ids={ids}
          docs={docs}
          edits={edits}
          setEdits={handleSetEdits}
          duplicate={duplicate}
          toggleDuplicate={toggleDuplicate}
          bindReloadColOpts={bindReloadColOpts}
          edit={edit}
          completed={completed}
          selectedBatchEditId={selectedBatchEditId}
          printTemplateKey={printTemplateKey}
          printCallback={complete}
          {...batchTemplateProps}
        />
      )}
    </>
  );
};

export default Batch;
