import React from 'react';
import { object } from 'prop-types';

const Event = ({ event }) => {
  const handleClick = () => {
    // alert(`Name: ${event.title}`);
  };

  // event.form: data to display
  return (
    <span onClick={handleClick}>
      <strong>{event.title}</strong>
      {/* {event.form} */}
    </span>
  );
};

Event.propTypes = {
  event: object,
};

export default Event;
