import React from 'react';

import styled from 'styled-components';
import { Item, Button, Icon, Progress, Grid } from 'semantic-ui-react';

const StyledMetaItem = styled(Item.Meta)`
  & {
    color: ${({ theme: { color } }) => color.popupFg} !important;
  }
`;

const Container = styled(Item)`
  &&& {
    padding: 1em 1em;
    border-radius: 0.28571429rem;
    border: ${({ theme: { color } }) => color.border} !important;
    cursor: pointer;
    background-color: ${({ theme: { color } }) => color.bgGeneral} !important;

    :hover,
    :focus,
    :active {
      background-color: ${({ theme: { color } }) =>
        color.tableItemBgHover} !important;
    }
  }
`;

const DriverName = styled.h4`
  margin-bottom: 0;
`;

const CapacityContainer = styled.div`
  position: relative;
`;
const CapacityProgress = styled(Progress)`
  &&& {
    margin: 0;
  }
`;
const CapacityEmptyProgress = styled(Progress)`
  &&& {
    margin: 0;

    .bar {
      background-color: transparent;
    }
  }
`;
const PercentValue = styled.span`
  position: absolute;
  top: 3px;
  text-align: center;
  width: 100%;
  z-index: 99;
`;

const StyledButtonLink = styled(Button)`
  &&&&& {
    background: transparent;
    color: ${({ theme: { color } }) => color.buttonFg} !important;
  }

  &&&&&:hover {
    background-color: ${({ theme: { color } }) =>
      color.popupBgHover} !important;
  }
`;

const EditToolsContainer = styled.div`
  float: right;
`;

const StyledButton = ({ name, handleClick }) => (
  <Button icon circular as={StyledButtonLink} onClick={handleClick}>
    <Icon name={name} />
  </Button>
);

const EditTools = ({ handleEditMode }) => (
  <EditToolsContainer>
    <Grid.Row>
      <StyledButton name="pencil" handleClick={handleEditMode} />
    </Grid.Row>
    <Grid.Row>
      <StyledButton name="trash alternate" />
    </Grid.Row>
  </EditToolsContainer>
);

const DriverListItem = ({ info, handleClick, handleEditMode }) => {
  return (
    <Container onClick={handleClick}>
      <Item.Image size="tiny" src={info.avatar} circular />
      <Item.Content>
        <Grid columns={3}>
          <Grid.Column>
            <DriverName>{info.name}</DriverName>
            <StyledMetaItem>{info.status}</StyledMetaItem>
          </Grid.Column>
          <Grid.Column>
            <h4>Capacity</h4>
            <CapacityContainer>
              {info.capacity > 0 ? (
                <CapacityProgress percent={info.capacity} color="teal" />
              ) : (
                <CapacityEmptyProgress />
              )}
              <PercentValue>{`${info.capacity}%`}</PercentValue>
            </CapacityContainer>
          </Grid.Column>
          <Grid.Column>
            <EditTools handleEditMode={handleEditMode} />
          </Grid.Column>
        </Grid>
      </Item.Content>
    </Container>
  );
};

export default DriverListItem;
