import React from 'react';

import { Form } from 'semantic-ui-react';
import styled from 'styled-components';

const StyledFormField = styled(({ visible, ...props }) => (
  <Form.Field {...props} />
))`
  ${({ visible }) => visible === false && 'visibility: hidden'}

  .ui.form &.field.disabled {
    opacity: 1;

    > label,
    > div.action.input.disabled {
      opacity: 1;
    }

    > div.dropdown {
      background-color: ${({ theme: { color } }) =>
        color.inputDisabled} !important;
      opacity: 0.45 !important;
    }

    > div.disabled.checkbox {
      label {
        opacity: 1;
      }

      input[type='checkbox'],
      label::before {
        background-color: ${({ theme: { color } }) =>
          color.inputDisabled} !important;
        opacity: 0.45;
      }
    }
  }
`;

export default StyledFormField;
