import React from 'react';
import styled from 'styled-components';

import { ThemedDropdown } from 'components';

import getShadowByState from './helpers/getShadowByState';

const TrackedDropdown = styled(({ unchanged, saved, ...props }) => (
  <ThemedDropdown {...props} />
))`
  &&&&& {
    box-shadow: ${({ saved, unchanged }) => getShadowByState(saved, unchanged)};
  }
`;

export default TrackedDropdown;
