// i18n.js
// I18n
import i18n from 'i18next';

// loading translation files from external location
import backend from 'i18next-xhr-backend';

// dynamically fetching user language
import { initReactI18next } from 'react-i18next';

// import locales constant values
import { LOCALES } from 'core/utils/constant.js';

export const defaultLocale = localStorage.getItem('faster-lang')
  ? localStorage.getItem('faster-lang')
  : LOCALES.fr;

export const locales = [
  {
    key: LOCALES.en,
    value: LOCALES.en,
    text: 'English',
  },
  {
    key: LOCALES.fr,
    value: LOCALES.fr,
    text: 'Français',
  },
];

i18n
  .use(backend)
  .use(initReactI18next)
  .init({
    // all namespaces needed to fetch -> separate file to load
    ns: ['common', 'glossary', 'batch', 'settings', 'tracking'],

    // namespace char separator
    nsSeparator: '|',

    // default namespace
    defaultNS: 'glossary',

    // Dynamically fetching user language
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json',
    },

    keySeparator: false,

    lng: defaultLocale,
    // use en if detected language is not available
    // fallbackLng: defaultLocale,

    // debug mode for development
    debug: false,

    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
