import React from 'react';
import { Table } from 'semantic-ui-react';

import { ButtonLink } from 'components';
import styled from 'styled-components';

const StyledTableFooter = styled(Table.Footer)`
  &&& {
    border-color: ${({ theme: { color } }) => color.tableBorderColor};
    tr th {
      background-color: ${({ theme: { color } }) => color.tableHeaderBg};
      color: ${({ theme: { color } }) => color.tableFg};
    }
  }
`;

const FilterTableFooter = ({ t, options, handleClickFunc }) => (
  <StyledTableFooter>
    <Table.Row>
      <Table.HeaderCell colSpan="10">
        {options.length > 0 && (
          <ButtonLink
            text={t('common|Add')}
            handleClickFunc={handleClickFunc}
            icon
            iconName="plus"
          />
        )}
      </Table.HeaderCell>
    </Table.Row>
  </StyledTableFooter>
);

export default FilterTableFooter;
