import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import {
  FormDataCreators,
  getVisibleFormData,
} from 'core/form-data/formDataReducer';
import { ModalCreators } from 'core/modal/modalReducer';

import {
  FIELD_ACTION,
  FIELD_ACTION_TAG,
  FIELD_TYPE,
  SYSTEM_FIELDS,
  MODAL_TYPE,
} from 'core/utils/constant';
import RegexUtils from 'core/utils/regex';
import Utils from 'core/utils/utils';
import { typeMapper } from 'core/utils/mapper';
import { getVisibleRowsHelper } from 'core/utils/form';
import { isValidPopup } from 'core/utils/utilsModal';
import { Spinner } from 'components';

import getDependentVisibleElts from './helpers/getDependentVisibleElts';
import getDependentIsVisible from './helpers/getDependentIsVisible';
import Template from './Template';

export const DATE_FORMAT = 'DD/MM/YYYY';

const TemplateContainer = ({
  template,
  documentID,
  isNewForm,
  metaData,
  viewingUsers,
  onAutohide,
  onChangeFunc,
  onChangeMultiDatesFunc,
  updateFormDataValue,
  histories,
  area,
  module,
  // redux state
  formData,
  visibleFields,
  visibleSections,
  visibleCategories,
  // redux dispatch
  changeSize,
  openModal,
  closeModal,
  updateFormData,
}) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [focusedInput, setFocusedInput] = useState(null);
  const [fieldsNeedToUpdate, setFieldsNeedToUpdate] = useState([]);
  const [loading, setLoading] = useState(true);

  const [, setInnerFormData] = useState(formData);

  useEffect(() => {
    setInnerFormData(formData);
  }, [formData]);

  useEffect(() => {
    initTemplate();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const initTemplate = () => {
    const { rules } = template;

    if (formData && formData['Field12']) {
      const [startDateString, endDateString] = formData['Field12'].split('|');
      const newStartDate = Utils.toMomentObject(startDateString);
      const newEndDate = Utils.toMomentObject(endDateString);
      setStartDate(newStartDate);
      setEndDate(newEndDate);
    }

    // Form layout don't need to use autofill/hide (don't send change function to props)
    if (onChangeFunc) {
      // Init which field or section is visible
      const initVisibleElt = _.filter(
        rules,
        fa =>
          _.includes([FIELD_ACTION.show, FIELD_ACTION.visible], fa.action) &&
          fa.dependent === null
      );

      _.forEach(initVisibleElt, f => {
        // is initialization then only hide section/field because all sections/fields are visible at the beginning
        const { action, independent } = f;
        if (
          independent.value === null ||
          independent.value.toLowerCase() === 'false'
        ) {
          if (independent.tag === FIELD_ACTION_TAG.category) {
            independent.categories.forEach(cat => {
              onAutohide(
                independent.tag,
                Utils.buildKeyAutoHideForCategory(independent.key, cat),
                false
              );
            });
          } else {
            onAutohide(
              independent.tag,
              independent.key,
              action === FIELD_ACTION.show ? null : false // show:false => display:None (value=null); visible:false => visibility:hidden (value=false)
            );
          }
        }
      });

      // Init the default value for fields
      const initFieldsValue = _.filter(
        rules,
        fa =>
          fa.action === FIELD_ACTION.fill &&
          fa.dependent === null &&
          fa.dependentModal === null
      );

      _.forEach(initFieldsValue, f => {
        const { independent } = f;

        const filledData = {
          name: independent.key,
          value: independent.value,
          type: independent.type.toLowerCase(),
          checked: independent.value.toLowerCase() === 'true',
        };

        // If field value is empty then fill with init value
        if (isNewForm && formData[independent.key] === '') {
          updateFormData(null, filledData);
        }
      });

      // AutoFill base on rules which contains metaData (status for example)
      const metaAutoFill = _.filter(
        rules,
        fa =>
          fa.action === FIELD_ACTION.fill &&
          SYSTEM_FIELDS[fa.independent.key.toLowerCase()] &&
          fa.dependent !== null
      );

      _.forEach(metaAutoFill, f => {
        const { independent, dependent } = f;

        // case Status
        if (
          independent.key === SYSTEM_FIELDS.status &&
          independent.value === metaData[SYSTEM_FIELDS.status]
        ) {
          // matching status then fill value
          const filledData = {
            name: dependent.key,
            value: dependent.value,
            type: dependent.type.toLowerCase(),
            checked: dependent.value.toLowerCase() === 'true',
          };

          updateFormData(null, filledData);
        }
      });

      // Auto show/hide for fields or sections
      const autoVisibleElt = _.filter(
        rules,
        fa =>
          _.includes([FIELD_ACTION.show, FIELD_ACTION.visible], fa.action) &&
          fa.dependent !== null
      );

      const dependentVisibleElts = getDependentVisibleElts(autoVisibleElt); // group by dependent element

      // filter by each dependent element
      _.forEach(dependentVisibleElts, dependent => {
        onAutohide(
          dependent.tag,
          dependent.key,
          getDependentIsVisible(dependent, rules, formData, metaData)
        );
      });
    }

    setLoading(false);
  };

  const onDatesChange = ({ newStartDate, newEndDate }) => {
    setStartDate(newStartDate);
    setEndDate(newEndDate);

    if (newStartDate && newEndDate) {
      const multiDates = `${newStartDate.format(
        DATE_FORMAT
      )}|${newEndDate.format(DATE_FORMAT)}`;
      onChangeMultiDatesFunc(multiDates);
    }
  };

  const onBtnClick = async popup => {
    if (await isValidPopup(popup, setInnerFormData)) {
      closeModal();
    }
  };

  const showPopupNoti = popup => {
    const notiModalOptions = {
      title: popup.name,
      message: popup.message,
      btnTitle: popup.ok,
      onBtnClick: () => onBtnClick(popup),
      inputs: popup.inputs,
      formData,
      updateFormData,
      type: MODAL_TYPE.empty,
    };

    changeSize('tiny');
    openModal('popup-inputs-clean', notiModalOptions);
  };

  const onFocusChange = (e, inputKey, _inputType) => {
    if (e) {
      const { type } = e;
      const action =
        type === 'focus'
          ? FIELD_ACTION.focusIn
          : type === 'blur'
          ? FIELD_ACTION.focusOut
          : undefined;

      if (action === FIELD_ACTION.focusOut) {
        // process only focusOut
        const { rules } = template;
        const ruleModal = _.find(
          rules,
          fa =>
            fa.action === action &&
            fa.independent.tag === FIELD_ACTION_TAG.field &&
            fa.independent.key === inputKey &&
            fa.dependentModal != null &&
            fa.dependentModal.modalId !== undefined
        );

        if (ruleModal) {
          showPopupNoti(ruleModal.dependentModal);
        }
      }
    }
  };

  const onAutoComplete = (name, value) => {
    updateFormDataValue(name, value);
    setFieldsNeedToUpdate(_.uniq([{ tag: FIELD_ACTION_TAG.field, key: name }]));
  };

  const onAutoFields = (e, field, pattern) => {
    if (!field) {
      field = e instanceof HTMLElement ? e : e.target;
    }

    const { rules } = template;
    setFocusedInput(field.name);

    // change the value of selected field
    if (onChangeFunc) {
      // Use redux store then we don't need await
      RegexUtils.updateFormWithMatchedPattern(
        e,
        field,
        pattern,
        updateFormData
      );

      // Find all fields action that this field affect to other fields (not the initialization)
      const fieldsActionByIndependentField = _.filter(
        rules,
        fa =>
          fa.independent.tag === FIELD_ACTION_TAG.field &&
          fa.independent.key === field.name &&
          (fa.dependent !== null || fa.dependentModal !== null)
      );

      // Distinct the value of checkbox and normal field
      const filledValue = _.includes(
        [FIELD_TYPE.checkbox],
        typeMapper(field.type)
      )
        ? field.checked
        : field.value;

      const newFieldsNeedToUpdate = [];

      const fieldsActionFill = _.filter(fieldsActionByIndependentField, {
        action: FIELD_ACTION.fill,
        dependent: { tag: FIELD_ACTION_TAG.field },
      });

      // Autofill
      fieldsActionFill.forEach(fieldAction => {
        const { independent, dependent } = fieldAction;
        if (
          independent.value === filledValue ||
          (_.includes([FIELD_TYPE.checkbox], typeMapper(independent.type)) &&
            (independent.value.toLowerCase() === 'true') === filledValue)
        ) {
          const filledData = {
            name: dependent.key,
            value: dependent.value,
            type: dependent.type.toLowerCase(),
            checked: dependent.value.toLowerCase() === 'true',
          };

          newFieldsNeedToUpdate.push({
            tag: dependent.tag,
            key: dependent.key,
          });

          // auto fill the dependent fill
          updateFormData(e, filledData);
        }
      });

      const fieldsActionNoti = _.filter(
        fieldsActionByIndependentField,
        fa =>
          fa.action === FIELD_ACTION.fill &&
          fa.dependentModal !== null &&
          fa.dependentModal.modalId !== undefined &&
          ![FIELD_TYPE.file, FIELD_TYPE.photo].includes(fa.independent.type)
      );

      // AutoNoti
      fieldsActionNoti.forEach(fieldAction => {
        const { independent, dependentModal } = fieldAction;
        if (
          independent.value === filledValue ||
          (_.includes([FIELD_TYPE.checkbox], typeMapper(independent.type)) &&
            (independent.value.toLowerCase() === 'true') === filledValue)
        ) {
          // show popup
          showPopupNoti(dependentModal);
        }
      });

      const fieldsActionShowHide = _.filter(
        fieldsActionByIndependentField,
        fa => _.includes([FIELD_ACTION.show, FIELD_ACTION.visible], fa.action)
      );

      const dependentVisibleElts = getDependentVisibleElts(
        fieldsActionShowHide
      ); // group by dependent element

      // Autohide
      _.forEach(dependentVisibleElts, async dependent => {
        newFieldsNeedToUpdate.push({ tag: dependent.tag, key: dependent.key });

        await onAutohide(
          dependent.tag,
          dependent.key,
          getDependentIsVisible(
            dependent,
            rules,
            formData,
            metaData,
            field,
            filledValue
          )
        );
      });

      setFieldsNeedToUpdate(_.uniq(newFieldsNeedToUpdate));
    }
  };

  const getVisibleRows = _sections =>
    getVisibleRowsHelper(_sections, visibleSections);

  if (loading) return <Spinner />;

  return (
    <Template
      // state
      focusedInput={focusedInput}
      startDate={startDate}
      endDate={endDate}
      fieldsNeedToUpdate={fieldsNeedToUpdate}
      // props
      template={template}
      documentID={documentID}
      formData={formData}
      visibleFields={visibleFields}
      visibleCategories={visibleCategories}
      viewingUsers={viewingUsers}
      getVisibleRows={getVisibleRows}
      onAutoComplete={onAutoComplete}
      onAutoFields={onAutoFields}
      onDatesChange={onDatesChange}
      onFocusChange={onFocusChange}
      histories={histories}
      area={area}
      module={module}
    />
  );
};

const mapStateToProps = state => getVisibleFormData(state);

const mapDispatchToProps = dispatch => ({
  openModal: (modalType, options) =>
    dispatch(ModalCreators.openModal(modalType, options)),
  closeModal: () => dispatch(ModalCreators.closeModal()),
  changeSize: size => dispatch(ModalCreators.changeSize(size)),
  updateFormData: (event, field) =>
    dispatch(FormDataCreators.updateFormData(event, field)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TemplateContainer);
