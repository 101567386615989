import _ from 'lodash';

import BaseApi from 'api/baseApi';

import { FIELD_TYPE } from 'core/utils/constant';
import { typeMapper } from 'core/utils/mapper';

const documentApi = {
  getByID: (key, withTemplate, type, id, area) => {
    const urlArray = ['document'];

    if (withTemplate) {
      urlArray.push('withTemplate');
    } else if (type) {
      urlArray.push(area);
      urlArray.push(type);
    }

    urlArray.push(key);

    if (id) {
      urlArray.push(id);
    }

    return BaseApi.get(urlArray.join('/'));
  },

  getIDs: (filters, params) => {
    const apiFilters = [];

    _.forEach(filters, filter => {
      const apiFilter = {
        key: filter.column.key,
        operator: filter.operator,
        value: filter.value,
        type: filter.column.type,
      };

      const { value } = apiFilter;

      if (_.isArray(value) && value) {
        apiFilter.value = value.join('|');
      }

      if (
        _.includes([FIELD_TYPE.checkbox], typeMapper(filter.column.type)) &&
        value === undefined
      ) {
        apiFilter.value = false; // value by default
      }

      apiFilters.push(apiFilter);
    });

    return BaseApi.post(`document/id/filter`, { filters: apiFilters }, params);
  },

  getByIDs: (ids, params) => BaseApi.post(`document/id`, { ids }, params),
  edit: edit => BaseApi.post('document/edit', { edit }),
  duplicate: edit => BaseApi.post('document/duplicate', { edit }),
  post: doc => BaseApi.post(`document`, { document: doc }),
  put: doc => BaseApi.put(`document`, { document: doc }),
  print: documents => BaseApi.post(`document/print`, { documents }),
};

export default documentApi;
