import React from 'react';
import { Label } from 'semantic-ui-react';
import styled from 'styled-components';

const StyledLabel = styled(Label).attrs(props => ({
  basic: true,
  ribbon: true,
}))`
  &.ui.ribbon.label {
    left: calc(-0.05rem - 1.2em);
  }
`;

const StyledLabelLink = styled.a.attrs(props => ({
  target: '_blank',
}))``;

const InputFileLabel = ({ isImageStored, content, imageStoredUrl }) =>
  isImageStored ? (
    <StyledLabelLink as={StyledLabel} content={content} href={imageStoredUrl} />
  ) : (
    <StyledLabel content={content} />
  );

export default InputFileLabel;
