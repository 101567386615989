import React from 'react';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Header, Icon, Label } from 'semantic-ui-react';
import styled from 'styled-components';

import { MODAL_TYPE } from 'core/utils/constant';
import { ThemedButton } from 'components';

const Container = styled.div``;

const StyledHeader = styled(Header.Content)`
  && {
    &.content {
      color: ${({ theme: { color } }) => color.fg};
      width: 100%;
    }
  }
`;

const StyledSubheader = styled(Header.Subheader)`
  &&& {
    &&.sub.header {
      color: ${({ theme: { color } }) => color.popupFg};
    }
  }
`;

const Content = styled.div`
  display: block;
  width: 100%;
  font-size: 1em;
  line-height: 1.4;
`;

const ButtonToolbar = styled.div`
  margin-top: 1em;
  text-align: right;
`;

const PrimaryButton = ({ btnTo, onBtnClick, btnTitle, popupInputs }) =>
  btnTo ? (
    <ThemedButton primary to={btnTo} onClick={onBtnClick} as={Link}>
      {btnTitle}
    </ThemedButton>
  ) : popupInputs ? (
    <ThemedButton primary form="popupForm" type="submit" onClick={onBtnClick}>
      {btnTitle}
    </ThemedButton>
  ) : (
    <ThemedButton primary onClick={onBtnClick}>
      {btnTitle}
    </ThemedButton>
  );

const SecondaryButton = ({
  secondaryBtnTo,
  onSecondaryBtnClick,
  secondaryBtnTitle,
}) =>
  secondaryBtnTo ? (
    <ThemedButton to={secondaryBtnTo} onClick={onSecondaryBtnClick} as={Link}>
      {secondaryBtnTitle}
    </ThemedButton>
  ) : (
    <ThemedButton onClick={onSecondaryBtnClick}>
      {secondaryBtnTitle}
    </ThemedButton>
  );

const PopupTemplateHeader = ({ t, type, modalType, title, children }) => (
  <Header as="h3">
    {!['popup-inputs-clean', 'form-action'].includes(modalType) && (
      <Icon
        color={type === MODAL_TYPE.warning ? 'yellow' : 'blue'}
        name={type === MODAL_TYPE.warning ? 'warning sign' : 'question circle'}
      />
    )}
    <StyledHeader>
      {!['popup-inputs-clean', 'form-action'].includes(modalType)
        ? `${t(`common|${type}`)} - ${title}`
        : title}
      <StyledSubheader>{children}</StyledSubheader>
    </StyledHeader>
  </Header>
);

const PopupTemplate = ({
  t,
  title,
  btnTo,
  onBtnClick,
  btnTitle,
  secondaryBtnTitle,
  secondaryBtnTo,
  onSecondaryBtnClick,
  children,
  warning,
  popupInputs,
  type = MODAL_TYPE.confirm,
  modalType,
}) => (
  <Container>
    <Content>
      <PopupTemplateHeader
        t={t}
        type={type}
        modalType={modalType}
        title={title}
        children={children}
      />
      {warning && (
        <Label basic color="red">
          {warning}
        </Label>
      )}
    </Content>
    <ButtonToolbar>
      {btnTitle && (
        <PrimaryButton
          btnTo={btnTo}
          btnTitle={btnTitle}
          onBtnClick={onBtnClick}
          popupInputs={popupInputs}
        />
      )}
      {secondaryBtnTitle && (
        <SecondaryButton
          secondaryBtnTo={secondaryBtnTo}
          secondaryBtnTitle={secondaryBtnTitle}
          onSecondaryBtnClick={onSecondaryBtnClick}
        />
      )}
    </ButtonToolbar>
  </Container>
);

export default withTranslation()(PopupTemplate);
