import React from 'react';
import { useTranslation } from 'react-i18next';

import { INVALID_FILE_TYPE, FILE_SIZE_LIMIT } from 'core/utils/constant';

import { ToastMessage } from 'components';

const ToastFileError = ({ invalidType }) => {
  const { t } = useTranslation();

  switch (invalidType) {
    case INVALID_FILE_TYPE.invalidImageExtension:
      return <ToastMessage error message={'Invalid image file type.'} />;

    case INVALID_FILE_TYPE.invalidFileExtension:
      return <ToastMessage error message={'Invalid file type.'} />;

    case INVALID_FILE_TYPE.invalidSize:
    default:
      return (
        <ToastMessage
          error
          message={`${t(
            'common|The file size can not exceed'
          )} ${FILE_SIZE_LIMIT}MB`}
        />
      );
  }
};

export default ToastFileError;
