import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Button, Dropdown, Form, Icon } from 'semantic-ui-react';
import _ from 'lodash';

import Utils from 'core/utils/utils';
import { getTemplates } from 'core/template/templateReducer';
import { ThemedButton, ThemedSegment } from 'components';

const BatchAction = ({
  // stores
  t,
  templates,
  // props
  filtered,
  ids,
  preview,
  step,
  currentUser,
  selectEdits,
  printTemplateKey,
  batchPrint,
}) => {
  const step2Props = {
    labelPosition: 'left',
    disabled: step === 2 && !_.isEmpty(ids) ? undefined : true,
  };

  const printOptions = _.filter(templates, t => t.key && t.key.length > 0).map(
    t => ({
      value: t.key,
      text: t.name,
      key: t.key,
    })
  );

  const [templateKey, setTemplateKey] = useState(
    printTemplateKey ? printTemplateKey : printOptions[0].key
  );

  const changePrint = (e, { value }) => {
    setTemplateKey(value);
    batchPrint(value);
  };

  return (
    <>
      {!filtered && (
        <ThemedSegment>
          <Icon name="circle notched" loading />
          {` ${t('Just one moment')}`}
        </ThemedSegment>
      )}
      {filtered && (
        <ThemedSegment>
          <Form>
            <Form.Field>
              <label>{t('common|Results')}</label>
              <p>
                <Icon name={!_.isEmpty(ids) ? 'check' : 'times'} /> {ids.length}{' '}
                {t('batch|matching form(s) found')}
              </p>
            </Form.Field>
            <Form.Field label="Actions" />
            <Form.Field>
              <ThemedButton
                primary
                icon="table"
                onClick={preview}
                content={t('common|Batch preview')}
                {...step2Props}
              />
            </Form.Field>
            {Utils.canPerform(currentUser, 'batchedit') && (
              <Form.Field>
                <ThemedButton
                  primary
                  icon="pencil"
                  onClick={selectEdits}
                  content={t('common|Batch edit')}
                  {...step2Props}
                />
              </Form.Field>
            )}
            <Form.Field>
              <Button.Group>
                <ThemedButton
                  primary
                  icon="print"
                  onClick={() => batchPrint(templateKey)}
                  content={t('common|Batch print')}
                  {...step2Props}
                />
                <Dropdown
                  as={Button}
                  primary
                  compact
                  className="icon"
                  value={templateKey}
                  options={printOptions}
                  trigger={<></>}
                  onChange={changePrint}
                  style={{ margin: '0.2em 0' }}
                />
              </Button.Group>
            </Form.Field>
          </Form>
        </ThemedSegment>
      )}
    </>
  );
};

const mapStateToProps = state => ({
  templates: getTemplates(state),
});

export default connect(
  mapStateToProps,
  null
)(BatchAction);
