import React from 'react';
import { func, string, number, arrayOf, object } from 'prop-types';
import { Icon, Label, Header, Popup } from 'semantic-ui-react';
import styled from 'styled-components';

import { FormHeader } from 'scenes/dashboard/styles/StyledComponents';
import { ThemedSegment } from 'components';

const ListLabel = styled(Label)`
  &&& {
    display: block;
    margin: 0 0 5px 0;
  }
`;

const StyledLabel = styled(Label)`
  color: ${({ theme: { color } }) => color.buttonFg} !important;
  background-color: ${({ theme: { color } }) => color.labelBoxBg} !important;
`;

const TemplateHeader = ({ t, name, documentID, viewingUsers }) => {
  return (
    <ThemedSegment>
      <Header as={FormHeader} textAlign="center">
        {name}
        {documentID > 0 && (
          <div>
            <StyledLabel>{`ID ${documentID}`}</StyledLabel>
            {viewingUsers.length > 0 && (
              <Popup
                content={viewingUsers.map(({ connectionId, username }) => (
                  <ListLabel key={connectionId}>{username}</ListLabel>
                ))}
                header={t('glossary|These users are viewing this document')}
                trigger={
                  <Label color="red" basic size="tiny">
                    <Icon name="eye" />
                    {viewingUsers.length}
                  </Label>
                }
                position="bottom center"
                size="tiny"
              />
            )}
          </div>
        )}
      </Header>
    </ThemedSegment>
  );
};

TemplateHeader.propTypes = {
  t: func.isRequired,
  name: string.isRequired,
  documentID: number,
  viewingUsers: arrayOf(object),
};

TemplateHeader.defaultProps = {
  documentID: undefined,
  viewingUsers: [],
};

export default TemplateHeader;
