import React, { useState } from 'react';
import {
  Map,
  LayerGroup,
  Marker,
  TileLayer,
  Popup,
  Tooltip,
} from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet-polylinedecorator';
import 'leaflet-routing-machine';
import 'leaflet-control-geocoder';
import 'lrm-graphhopper';
import _ from 'lodash';

import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import PolylineDecorator from './components/PolylineDecorator';

let DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
});

L.Marker.prototype.options.icon = DefaultIcon;

const DEFAULT_VIEWPORT = {
  center: [45.500572, -75.72331],
  zoom: 13,
};

const COLORS = ['black', 'red', 'blue', 'yellow', 'green'];

const DispatchMap = ({
  mapRef,
  markersData,
  locationsOptimized,
  addMarker,
}) => {
  const [viewport, setViewport] = useState(DEFAULT_VIEWPORT);

  const onViewportChanged = newViewport => {
    setViewport(newViewport);
  };

  return (
    <Map
      ref={mapRef}
      onViewportChanged={onViewportChanged}
      viewport={viewport}
      style={{ width: '100%', height: '640px' }}
      onClick={addMarker}
    >
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
      <LayerGroup>
        {markersData.map((m, i) => {
          const position = [m[0], m[1]];
          return (
            <Marker key={`marker-${m[2]}`} position={position}>
              <Popup>
                <div>
                  <b>Marker {m[2]}</b>
                </div>
                <div>Lat: {m[0]}</div>
                <div>Lng: {m[1]}</div>
              </Popup>
              <Tooltip>
                <div>
                  <b>Marker {m[2]}</b>
                </div>
                <div>Lat: {m[0]}</div>
                <div>Lng: {m[1]}</div>
              </Tooltip>
            </Marker>
          );
        })}
        {locationsOptimized &&
          _.map(locationsOptimized, (p, i) => (
            <PolylineDecorator
              key={i}
              mapRef={mapRef}
              color={COLORS[i]}
              weight={3}
              opacity={0.5}
              positions={p.path}
            />
          ))}
      </LayerGroup>
    </Map>
  );
};

export default DispatchMap;
