import moment from 'moment';

const defaultDateFormat = 'YYYY-MM-DD';
const altDateFormat = 'DD/MM/YYYY';

moment.updateLocale('ca', { longDateFormat: { L: defaultDateFormat } });
moment.updateLocale('be', { longDateFormat: { L: altDateFormat } });
moment.updateLocale('fr', { longDateFormat: { L: altDateFormat } });
moment.updateLocale('ch', { longDateFormat: { L: altDateFormat } });

const getDateFormatString = (
  countryCode = sessionStorage.getItem('countryCode')
) => {
  moment.locale(countryCode);
  let dateFormat = defaultDateFormat;
  if (countryCode === 'fr' || countryCode === 'ch' || countryCode === 'be') {
    dateFormat = altDateFormat;
  }
  return dateFormat;
};

const getDateTimeFormatted = (
  isoString,
  dateFormat = getDateFormatString(),
  timeFormat = 'HH:mm:ss'
) => {
  if (!timeFormat) {
    return moment(isoString).format(`${dateFormat}`);
  } else {
    return moment(isoString).format(`${dateFormat} ${timeFormat}`);
  }
};

export { getDateFormatString, getDateTimeFormatted };
