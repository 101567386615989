export default {
  drivers: [
    {
      id: 1,
      name: 'John P.',
      avatar: '/Stockage/Image/logo.png',
      capacity: 40,
      status: 'On transit',
    },
    {
      id: 2,
      name: 'Joe W.',
      avatar: '/Stockage/Image/logo.png',
      capacity: 0,
      status: 'Not working',
    },
    {
      id: 3,
      name: 'Alex D.',
      avatar: '/Stockage/Image/logo.png',
      capacity: 90,
      status: 'Not working',
    },
    {
      id: 4,
      name: 'Alex D.',
      avatar: '/Stockage/Image/logo.png',
      capacity: 90,
      status: 'Not working',
    },
    {
      id: 5,
      name: 'Alex D.',
      avatar: '/Stockage/Image/logo.png',
      capacity: 90,
      status: 'Not working',
    },
    {
      id: 6,
      name: 'Alex D.',
      avatar: '/Stockage/Image/logo.png',
      capacity: 90,
      status: 'Not working',
    },
  ],
};
