import React from 'react';
import { withTranslation } from 'react-i18next';
import { Table } from 'semantic-ui-react';
import styled from 'styled-components';

import { Spinner, InputSearch } from 'components';

const StyledHeaderCellFilterLoading = styled(Table.HeaderCell)`
  &&& {
    background-color: ${({ theme: { color } }) =>
      color.tableHeaderBg} !important;
    color: ${({ theme: { color } }) => color.fg} !important;

    :hover {
      color: ${({ theme: { color } }) => color.tableFgSelected} !important;
      background-color: ${({ theme: { color } }) =>
        color.tableHeaderBgHover} !important;
    }
  }
`;

const StyledHeaderCellFilter = styled(Table.HeaderCell)`
  &&& {
    padding: 0.5rem;
    position: relative;
    min-width: 7rem;

    background-color: ${({ theme: { color } }) => color.tableHeaderBg};

    &:hover {
      background-color: ${({ theme: { color } }) =>
        color.tableHeaderBgHover} !important;
      color: ${({ theme: { color } }) => color.tableFg};
    }

    > div:first-child {
      margin-bottom: 0.3em;
      color: ${({ theme: { color } }) => color.tableFg};
    }

    > div:nth-child(2) {
      width: 100%;
    }
  }
`;

const HeaderCellFilter = ({
  t,
  activeColumn,
  c,
  direction,
  handleSearchFieldChange,
  handleSort,
}) => (
  <StyledHeaderCellFilter
    sorted={activeColumn === c.key ? direction : null}
    onClick={handleSort(c.key)}
  >
    <div>{c.name}</div>
    <InputSearch
      t={t}
      fluid
      column={c}
      handleValue={handleSearchFieldChange}
      clearable
    />
  </StyledHeaderCellFilter>
);

const MainGridTableHeader = ({
  t,
  table,
  activeColumn,
  direction,
  handleSort,
  handleSearchFieldChange,
}) => {
  if (!table || !table.columns) {
    return <StyledHeaderCellFilterLoading content={<Spinner />} />;
  }

  if (table.columns.length < 1) {
    return (
      <StyledHeaderCellFilterLoading
        content={t('No fields found, please add fields to see the results')}
      />
    );
  }

  return table.columns.map(c => (
    <HeaderCellFilter
      key={c.key}
      c={c}
      t={t}
      direction={direction}
      activeColumn={activeColumn}
      handleSort={handleSort}
      handleSearchFieldChange={handleSearchFieldChange}
    />
  ));
};

export default withTranslation()(MainGridTableHeader);
