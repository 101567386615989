import { Message } from 'semantic-ui-react';
import styled from 'styled-components';

const ThemedMessage = styled(Message)`
  &&& {
    background-color: ${({ theme: { color } }) => color.messageBg};
  }
`;

export default ThemedMessage;
