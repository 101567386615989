import React from 'react';
import { Table } from 'semantic-ui-react';
import styled from 'styled-components';

const StyledHeaderCellFilter = styled(Table.HeaderCell)`
  &&&& {
    background-color: ${({ theme: { color } }) => color.tableHeaderBg};
    color: ${({ theme: { color } }) => color.tableFg};
    border-color: ${({ theme: { color } }) => color.tableBorderColor};

    > div:first-child {
      margin-bottom: 0.3em;
      color: ${({ theme: { color } }) => color.tableFg};
    }

    > div:nth-child(2) {
      width: 100%;

      &,
      & div,
      input {
        color: ${({ theme: { color } }) => color.tableFg};
        background-color: ${({ theme: { color } }) => color.tableBg} !important;

        + i {
          color: ${({ theme: { color } }) => color.tableFg};
        }
      }
    }

    &:hover {
      background-color: ${({ theme: { color } }) =>
        color.tableHeaderBgHover} !important;
      color: ${({ theme: { color } }) => color.tableFg};
    }
  }
`;

const FilterTableHeader = ({ t, advanced }) => (
  <Table.Header>
    <Table.Row>
      <StyledHeaderCellFilter>{t('Template')}</StyledHeaderCellFilter>
      <StyledHeaderCellFilter>{t('Section')}</StyledHeaderCellFilter>
      <StyledHeaderCellFilter>{t('Field')}</StyledHeaderCellFilter>
      {advanced && <StyledHeaderCellFilter />}
      <StyledHeaderCellFilter>{t('Value')}</StyledHeaderCellFilter>
      <StyledHeaderCellFilter />
    </Table.Row>
  </Table.Header>
);

const GridFilterTableHeader = ({ t, advanced }) => (
  <Table.Header>
    <Table.Row>
      <StyledHeaderCellFilter width={1}>{t('Position')}</StyledHeaderCellFilter>
      <StyledHeaderCellFilter>{t('Template')}</StyledHeaderCellFilter>
      <StyledHeaderCellFilter>{t('Section')}</StyledHeaderCellFilter>
      <StyledHeaderCellFilter>{t('Field')}</StyledHeaderCellFilter>
      {advanced && <StyledHeaderCellFilter />}
      <StyledHeaderCellFilter>{t('Value')}</StyledHeaderCellFilter>
      <StyledHeaderCellFilter width={1} />
    </Table.Row>
  </Table.Header>
);

export { FilterTableHeader, GridFilterTableHeader };
