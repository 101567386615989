import React from 'react';
import { Table } from 'semantic-ui-react';
import { StyledPagination } from 'scenes/dashboard/styles/StyledComponents';
import { ThemedTable } from 'components';

const TableExamplePagination = () => (
  <ThemedTable sortable celled selectable structured>
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell>Header</Table.HeaderCell>
        <Table.HeaderCell>Header</Table.HeaderCell>
        <Table.HeaderCell>Header</Table.HeaderCell>
      </Table.Row>
    </Table.Header>

    <Table.Body>
      <Table.Row>
        <Table.Cell>First</Table.Cell>
        <Table.Cell>Cell</Table.Cell>
        <Table.Cell>Cell</Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell>Cell</Table.Cell>
        <Table.Cell>Cell</Table.Cell>
        <Table.Cell>Cell</Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell>Cell</Table.Cell>
        <Table.Cell>Cell</Table.Cell>
        <Table.Cell>Cell</Table.Cell>
      </Table.Row>
    </Table.Body>

    <Table.Footer>
      <Table.Row>
        <Table.HeaderCell colSpan="3">
          <StyledPagination
            defaultActivePage={5}
            totalPages={10}
            firstItem={null}
            lastItem={null}
            prevItem={null}
            nextItem={null}
            floated="right"
          />
        </Table.HeaderCell>
      </Table.Row>
    </Table.Footer>
  </ThemedTable>
);

export default TableExamplePagination;
