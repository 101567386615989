import React from 'react';
import { Button } from 'semantic-ui-react';
import styled from 'styled-components';

const ButtonAsLink = styled(Button).attrs({
  basic: true,
  primary: true,
  type: 'button',
})`
  &&&&,
  &&&&:hover,
  &&&&:focus,
  &&&&:target {
    border: 0 !important;
    border-radius: 0 !important;
    box-shadow: none !important;
    padding: 0.4em 0.2em;
    color: ${({ theme: { color } }) => color.primary} !important;
  }

  &&&&:hover {
    border-radius: 0 !important;
    box-shadow: 0 0.1em 0 -0.05em !important;
  }
`;

const ButtonLink = ({ text, icon, iconName, handleClickFunc }) => (
  <ButtonAsLink
    onClick={handleClickFunc}
    content={text}
    icon={icon && iconName}
  />
);

export default ButtonLink;
