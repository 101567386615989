import React from 'react';
import MainGrid from '../main-grid/mainGridContainer';
import PageSegment from './PageSegment';

const TableSegment = ({ area, module, tableKey, ids, filters, ...props }) => (
  <PageSegment {...props}>
    <MainGrid
      module={module}
      area={area}
      tableKey={tableKey}
      ids={ids}
      filters={filters}
    />
  </PageSegment>
);

export default TableSegment;
