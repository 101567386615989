import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  *,
  *::after,
  *::before {
    box-sizing: border-box;
  }

  body{
    background-color: ${({ theme: { color } }) => color.bg};
    color: ${({ theme }) => theme.fg};
  }

  .blurring.dimmed.dimmable>:not(.dimmer) {
    filter: none;
  }

  .blurring.dimmed.dimmable>#renderBody>div:first-child {
    -webkit-filter: blur(5px) grayscale(.7);
    filter: blur(5px) grayscale(.7);
  }
`;

export default GlobalStyle;
