import React from 'react';
import { Checkbox, Form, Icon, Message } from 'semantic-ui-react';
import _ from 'lodash';

import {
  Filter,
  ResponsiveButtonCore,
  TableSegment,
  ThemedMessage,
  ThemedSegment,
} from 'components';

import BatchTemplates from '../templates/BatchTemplates';
import PrintTrigger from './PrintTrigger';

const BatchPerform = ({
  t,
  module,
  action,
  ids,
  docs,
  duplicate,
  toggleDuplicate,
  batchTemplates,
  batchTempOptions,
  selectedBatchEditId,
  handleChangeBatchTemplate,
  edits,
  setEdits,
  bindReloadColOpts,
  edit,
  completed,
  printTemplateKey,
  printCallback,
}) => (
  <>
    {action === 'preview' && (
      <TableSegment
        icon="list"
        module={module}
        ids={ids}
        tableKey="main|preview"
      />
    )}
    {action === 'edit' && docs.length <= 0 && (
      <>
        {!_.isEmpty(batchTemplates) && (
          <BatchTemplates
            t={t}
            value={selectedBatchEditId}
            batchTempOptions={batchTempOptions}
            handleChangeBatchTemplate={handleChangeBatchTemplate}
          />
        )}
        <ThemedSegment>
          <Form>
            <Form.Field>
              <label>{t('common|Values')}</label>
              <Filter
                filters={edits}
                callbackFunc={setEdits}
                handleReloadColOpts={bindReloadColOpts}
              />
            </Form.Field>
            <ThemedMessage>
              <Form.Field>
                <label>
                  {t(
                    'batch|Check this option to duplicate previously selected documents before applying edits'
                  )}
                </label>
              </Form.Field>
              <Form.Field
                control={Checkbox}
                label={t(
                  'batch|Duplicate documents and apply edits only to the duplicates'
                )}
                name="duplicate"
                checked={duplicate}
                onChange={toggleDuplicate}
              />
            </ThemedMessage>
            <Form.Field>
              <ResponsiveButtonCore
                primary
                icon="check"
                type="button"
                labelPosition="left"
                onClick={edit}
                disabled={edits.length < 1 || _.some(edits, { columnID: -1 })}
                content={t('common|Confirm')}
              />
            </Form.Field>
          </Form>
        </ThemedSegment>
      </>
    )}
    {action === 'print' && (
      <ThemedSegment>
        <ThemedMessage icon positive={completed ? true : undefined}>
          <Icon
            name={completed ? 'check' : 'circle notched'}
            loading={!completed}
          />
          <Message.Content>
            <Message.Header>
              {completed ? t('Success') : t('Just one moment')}
            </Message.Header>
            {docs.length > 0
              ? completed
                ? `${t('Printed')} ${docs.length} ${t('form(s)')}`
                : `${t('...Printing')} ${docs.length} ${t('form(s)')}`
              : ''}
          </Message.Content>
        </ThemedMessage>
        {docs.length > 0 && (
          <PrintTrigger
            docs={docs}
            callback={printCallback}
            printTemplateKey={printTemplateKey}
          />
        )}
      </ThemedSegment>
    )}
  </>
);

export default BatchPerform;
