import React from 'react';
import styled from 'styled-components';
import { Form, Checkbox } from 'semantic-ui-react';

import getShadowByState from './helpers/getShadowByState';

const TrackedCheckbox = styled(
  ({ value, unchanged, saved, type, ...props }) => (
    <Form.Field control={Checkbox} checked={value} {...props} />
  )
)`
  &&&&& label::before {
    box-shadow: ${({ saved, unchanged }) => getShadowByState(saved, unchanged)};
  }

  &&& {
    label,
    input:active:checked ~ label,
    input:checked ~ label {
      color: ${({ theme: { color } }) => color.fg} !important;
    }
  &.toggle label {
    &::before {
      background-color: ${({ theme: { color } }) => color.checkboxBg};
    }
    &:hover::before {
      background-color: ${({ theme: { color } }) => color.checkboxBgHover};
    }
  }
`;

export default TrackedCheckbox;
