import React, { memo, Fragment } from 'react';
import { object } from 'prop-types';
import { Grid } from 'semantic-ui-react';
import _ from 'lodash';

import Utils from 'core/utils/utils';
import { getVisibleRowsHelper } from 'core/utils/form';

import FormSectionGridRow from './components/FormSectionGridRow';
import FormSectionHeader from './components/FormSectionHeader';
import FormSectionFieldSet from './components/FormSectionFieldSet';
import areEqual from './helpers/areEqual';

const FormSection = ({
  section: { sectionID, name, key, fields, color, visible },
  ...formSectionProps
}) => {
  const { visibleFields } = formSectionProps;

  return (
    <Fragment key={sectionID > 0 ? sectionID : key}>
      {!_.isNil(visible) && (
        <>
          <FormSectionHeader name={name} visible={visible} />
          <Grid
            as={FormSectionFieldSet}
            stackable
            columns="equal"
            color={Utils.getColor(color)}
            visible={visible}
          >
            {getVisibleRowsHelper(fields, visibleFields).map(row => {
              const rowSpan = Utils.getRowSpan(row, fields);
              return (
                <FormSectionGridRow
                  key={row}
                  formSectionProps={formSectionProps}
                  {...rowSpan}
                />
              );
            })}
          </Grid>
        </>
      )}
    </Fragment>
  );
};

FormSection.propTypes = {
  section: object.isRequired,
};

export default memo(FormSection, areEqual);
