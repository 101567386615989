import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Views } from 'react-big-calendar';
import moment from 'moment';
import _ from 'lodash';

import { useApi } from 'api/useApi';
import { useHub } from 'api/useHub';
import tableApi from 'api/table/tableApi';
import tableDataApi from 'api/tableData/tableDataApi';
import tableHub from 'api/table/tableHub';

import { history } from 'config/store';
// import useFetchForms from 'core/hooks/useFetchForms';
import { useForm } from 'core/hooks/useForm';
import { LOCALES } from 'core/utils/constant';
import Utils from 'core/utils/utils';

import formToEvent from './helpers/formToEvent';
import FasterAgenda from './FasterAgenda';
import {
  getStartDateDay,
  getEndDateDay,
  getEndDateWeek,
  getStartDateWeek,
  getStartDateMonth,
  getEndDateMonth,
} from './helpers/calendarDate';

const { WEEK, MONTH } = Views;

const filterTableColumns = table => ({
  ...table,
  columns: _.filter(table.columns, col => col.position > 0),
});

const FasterAgendaContainer = ({
  module,
  area,
  tableKey,
  token,
  filters,
  selectedFilters,
}) => {
  const ifFrechFormat = localStorage.getItem('faster-lang') === LOCALES.fr;

  const [form, , handleSetKey] = useForm({
    agendaFieldKeyFrom: null,
    agendaFieldKeyTo: null,
    events: null,
    color: null,
    currentView: WEEK,
    currentDate: new Date(),
    dateRange: {
      startDate: getStartDateWeek(new Date(), ifFrechFormat),
      endDate: getEndDateWeek(new Date(), ifFrechFormat),
    },
  });

  // fetch table
  const [args] = useState([tableKey]);
  const [fetchedTable, pendingTable] = useApi(tableApi.getTable, args);

  const initializecriteria = () => {
    const initCrit = { searchByInput: {} };

    if (filters && filters.length > 0) {
      _.forOwn(filters, value => {
        _.forOwn(value, (val, key) => {
          initCrit.searchByInput[key] = val;
        });
      });
    }

    if (selectedFilters && selectedFilters.length > 0) {
      _.forEach(selectedFilters, item => {
        initCrit.searchByInput[item.key] = item.value;
      });
    }

    return initCrit;
  };

  // fetch data
  const canRefetch = useRef(false);

  const [loading, setLoading] = useState(false);
  const [criteria] = useState(initializecriteria());
  const [query, setQuery] = useState();
  const [argsData, setArgsData] = useState([
    query,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
  ]);

  const reFetch = useCallback(() => {
    setQuery(query);
    setArgsData([query, undefined, undefined, undefined, undefined, undefined]);
  }, [query]);

  const [fetchedData, pendingData] = useApi(tableDataApi.getMany, argsData, {
    tableData: undefined,
  });
  useHub(tableHub, token, reFetch);

  useEffect(() => {
    if (canRefetch.current) {
      reFetch();
    } else {
      canRefetch.current = true;
    }
  }, [reFetch]);

  useEffect(() => {
    if (!pendingTable) {
      const table = filterTableColumns(fetchedTable.table);

      const { color, agendaFieldKeyFrom, agendaFieldKeyTo } = table;
      handleSetKey('agendaFieldKeyFrom', agendaFieldKeyFrom);
      handleSetKey('agendaFieldKeyTo', agendaFieldKeyTo);
      handleSetKey('color', color);

      // Criteria for data
      if (agendaFieldKeyFrom && agendaFieldKeyTo) {
        const { startDate, endDate } = form.dateRange;

        const dateRangeQuery = `${startDate}|${endDate}`;

        criteria.searchByInput[agendaFieldKeyFrom] = dateRangeQuery;
        criteria.searchByInput[agendaFieldKeyTo] = dateRangeQuery;
      }

      setQuery(tableDataApi.buildQuery(tableKey, table.type, criteria));
    }
  }, [
    pendingTable,
    form.dateRange,
    criteria,
    handleSetKey,
    tableKey,
    fetchedTable,
  ]);

  useEffect(() => {
    if (!pendingData) {
      const { rows } = fetchedData.tableData;
      const { agendaFieldKeyFrom, agendaFieldKeyTo } = fetchedTable.table;

      let rowsToFilter = [...rows];

      if (agendaFieldKeyFrom && agendaFieldKeyTo) {
        rowsToFilter = _.filter(rowsToFilter, f => f.from && f.to);
      }

      handleSetKey('events', _.map(rowsToFilter, f => formToEvent(f)));

      setLoading(false);
    }
  }, [fetchedData, fetchedTable, pendingData, handleSetKey]);

  const handleSetDateRange = (date, view) => {
    const {
      currentDate,
      currentView,
      agendaFieldKeyFrom,
      agendaFieldKeyTo,
    } = form;

    const isSameDateView =
      moment(date)
        .startOf('day')
        .isSame(moment(currentDate).startOf('day')) && view === currentView;

    if (!isSameDateView && agendaFieldKeyFrom && agendaFieldKeyTo) {
      let startDate, endDate;
      setLoading(true);

      if (view === WEEK) {
        startDate = getStartDateWeek(date, ifFrechFormat);
        endDate = getEndDateWeek(date, ifFrechFormat);
      } else if (view === MONTH) {
        startDate = getStartDateMonth(date);
        endDate = getEndDateMonth(date);
      } else {
        startDate = getStartDateDay(date);
        endDate = getEndDateDay(date);
      }

      handleSetKey('dateRange', { startDate, endDate });
    }
  };

  const handleNavigate = (date, view) => {
    handleSetDateRange(date, view);
    handleSetKey('currentDate', date);
  };

  const handleChangeView = view => {
    handleSetDateRange(form.currentDate, view);
    handleSetKey('currentView', view);
  };

  const handleDoubleClick = event => {
    if (area || module) {
      const urlNav = Utils.buildUrlNav(area, module, event.key);
      history.push(urlNav);
    }
  };

  return (
    <FasterAgenda
      form={form}
      loading={loading}
      handleNavigate={handleNavigate}
      handleChangeView={handleChangeView}
      handleDoubleClick={handleDoubleClick}
    />
  );
};

FasterAgendaContainer.propTypes = {};

export default FasterAgendaContainer;

export { formToEvent };
