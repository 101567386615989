import React, { Component, createRef } from 'react';
import Axios from 'axios';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Segment } from 'semantic-ui-react';
import _ from 'lodash';

import {
  getIsMapNewWindow,
  DispatchCreators,
} from 'core/dispatch/dispatchReducer';
import Utils from 'core/utils/utils';
import { ThemedButton } from 'components';

import DispatchMap from './DispatchMap';
import { MatrixSymmetric } from './models/MatrixModel';
import { GHRequestInfo, GHResponseInfo } from './models/GHInfoModel';

const BASE_URL = 'http://localhost:8080/dispatch';
const DISPATCH_PATHNAME = '/dispatch/map';

// const postMatrix = async (
//   points,
//   outArrays = ['weights', 'times', 'distances'],
//   vehicle = 'car'
// ) => {
//   try {
//     const payload = { points, out_arrays: outArrays, vehicle };
//     return await Axios.post(`${BASE_URL}/matrix`, payload);
//   } catch (e) {
//     console.error(e);
//   }
// };

const postMatrixSymmetric = async points => {
  try {
    const ghRequestInfo = new GHRequestInfo();
    const ghResponseInfo = new GHResponseInfo();
    const payload = new MatrixSymmetric({
      points,
      ghRequestInfo,
      ghResponseInfo,
    });
    return await Axios.post(`${BASE_URL}/matrix-sym`, payload);
  } catch (e) {
    console.error(e);
  }
};

class DispatchMapWrapper extends Component {
  state = {
    markersData: [],
    markerIndex: 0,
    locationsOptimized: [],
    isError: false,
    isMapped: false,
    toggleMapContent: '',
    handleToggleMap: null,
  };
  mapRef = createRef();

  componentDidMount() {
    const {
      history,
      location: { pathname },
      closeMapNewWindow,
    } = this.props;

    // Detect changes of isNewMapWindow
    window.addEventListener(
      'storage',
      () => {
        const {
          location: { pathname },
          closeMapNewWindow,
        } = this.props;

        if (localStorage.getItem('isMapNewWindow') === null) {
          closeMapNewWindow();

          if (pathname === DISPATCH_PATHNAME) {
            window.close();
          } else {
            this.parseAndClearMapStorage();
          }
        }
      },
      false
    );

    // Close Map when close tab
    window.addEventListener('beforeunload', () => {
      if (localStorage.getItem('isMapNewWindow')) {
        this.keepStorageMapState(this.state);
      }
      closeMapNewWindow();
    });

    if (!localStorage.getItem('isMapNewWindow')) {
      // Must enter from dispatch page
      if (pathname === DISPATCH_PATHNAME) {
        history.push('/dispatch');
      }

      this.setState({
        toggleMapContent: 'Open',
        handleToggleMap: () => this.handleOpenNewMap(),
      });
    } else {
      // Keep current state
      this.parseAndClearMapStorage();

      this.setState({
        toggleMapContent: 'Close',
        handleToggleMap: () => this.handleCloseMap(),
      });
    }
  }

  clearStorageMapState = () => {
    localStorage.removeItem('markersData');
    localStorage.removeItem('markerIndex');
    localStorage.removeItem('locationsOptimized');
    localStorage.removeItem('isError');
  };

  keepStorageMapState = () => {
    const {
      markersData,
      markerIndex,
      locationsOptimized,
      isError,
    } = this.state;
    // Pass current state into localStorage
    localStorage.setItem('markersData', JSON.stringify(markersData));
    localStorage.setItem('markerIndex', markerIndex);
    localStorage.setItem(
      'locationsOptimized',
      JSON.stringify(locationsOptimized)
    );
    localStorage.setItem('isError', isError);
  };

  parseStorageMapState = async () => {
    const storageMarkersData = JSON.parse(localStorage.getItem('markersData'));
    const storageMarkerIndex = JSON.parse(localStorage.getItem('markerIndex'));
    const storageLocationsOptimized = JSON.parse(
      localStorage.getItem('locationsOptimized')
    );
    const storageIsError = localStorage.getItem('isError');

    this.setState({
      markerIndex: storageMarkerIndex,
      markersData: storageMarkersData,
      locationsOptimized: storageLocationsOptimized,
      isError: storageIsError === 'true',
    });
  };

  parseAndClearMapStorage = () => {
    if (localStorage.getItem('markersData') !== null) {
      this.parseStorageMapState();
      this.clearStorageMapState();
    }
  };

  addMarker = e => {
    this.setState({
      markerIndex: this.state.markerIndex + 1,
      markersData: [
        ...this.state.markersData,
        [e.latlng.lat, e.latlng.lng, this.state.markerIndex],
      ],
    });
  };

  onOptimize = async e => {
    const { markersData } = this.state;

    let points = [];
    markersData.forEach(m => {
      points.push(`${m[0]},${m[1]}`);
    });

    const data = await postMatrixSymmetric(points);

    if (data !== undefined) {
      // route optimized
      if (_.isEmpty(data)) {
        this.setState({ isError: true });
      } else {
        const tmp = [];
        for (let i = 0; i < data.length; i++) {
          const points = data[i].pointsOrdered;
          const path = Utils.decodePath(data[i].paths[0].points, false);
          const vehicle = data[i].vehicle;
          tmp.push({ points, path, vehicle });
        }

        this.setState({ isError: false, locationsOptimized: tmp });
      }
    }
  };

  clearMap = (map = this.mapRef.current.leafletElement) => {
    for (const i in map._layers) {
      if (map._layers[i]._path !== undefined) {
        try {
          map.removeLayer(map._layers[i]);
        } catch (e) {
          console.log('problem with ' + e + map._layers[i]);
        }
      }
    }
  };

  onReset = () => {
    this.setState({
      markersData: [],
      markerIndex: 0,
      locationsOptimized: null,
    });
    this.clearMap();
    this.clearStorageMapState();
  };

  handleOpenNewMap = () => {
    const { openMapNewWindow } = this.props;

    this.keepStorageMapState(this.state);
    openMapNewWindow();
    window.open(DISPATCH_PATHNAME, '_blank');
  };

  handleCloseMap = () => {
    const {
      closeMapNewWindow,
      location: { pathname },
    } = this.props;

    this.keepStorageMapState(this.state);
    closeMapNewWindow();
    if (pathname === DISPATCH_PATHNAME) {
      window.close();
    }
  };

  render() {
    const {
      markersData,
      locationsOptimized,
      isError,
      toggleMapContent,
      handleToggleMap,
    } = this.state;
    const { hidden } = this.props;

    return (
      <div style={{ display: hidden ? 'none' : undefined }}>
        <Segment basic>
          <ThemedButton
            size="mini"
            content="Optimize Matrix"
            onClick={this.onOptimize}
          />
          <ThemedButton size="mini" content="Reset" onClick={this.onReset} />
          <ThemedButton
            size="mini"
            content={`${toggleMapContent} map in new tab`}
            onClick={handleToggleMap}
          />
        </Segment>
        <DispatchMap
          mapRef={this.mapRef}
          markersData={markersData}
          locationsOptimized={locationsOptimized}
          addMarker={this.addMarker}
        />
        {isError && <Segment>Error</Segment>}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isMapNewWindow: getIsMapNewWindow(state),
});

const mapDispatchToProps = dispatch => ({
  closeMapNewWindow: () => dispatch(DispatchCreators.closeMapNewWindow()),
  openMapNewWindow: () => dispatch(DispatchCreators.openMapNewWindow()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(DispatchMapWrapper));
