import React from 'react';
import { Table } from 'semantic-ui-react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import _ from 'lodash';

import Utils from 'core/utils/utils';
import { typeMapper } from 'core/utils/mapper';
import { getDateTimeFormatted } from 'core/utils/date';
import { FIELD_TYPE, MENU_IDS } from 'core/utils/constant';
import { getTemplates } from 'core/template/templateReducer';
import { ContextMenuTrigger } from 'components';

const StyledRow = styled(
  ({ className, admin, children, attributes, viewed, ...props }) =>
    admin ? (
      <tr className={className}>{children}</tr>
    ) : (
      <ContextMenuTrigger
        renderTag="tr"
        attributes={{
          ...attributes,
          className: `${attributes.className} ${className}`,
        }}
        {...props}
      >
        {children}
      </ContextMenuTrigger>
    )
)`
  &&&& {
    box-shadow: ${({ viewed, admin, theme: { color } }) =>
      admin ? '' : viewed ? '' : `inset 2px 0 0 ${color.primary}`};
  }
`;

const StyledTableCell = styled(({ children, cellStyle, ...props }) => (
  <Table.Cell {...props}>{children}</Table.Cell>
))`
  &&& {
    color: ${({ cellStyle }) => (cellStyle ? cellStyle.color : '')};
    background-color: ${({ cellStyle }) =>
      cellStyle ? cellStyle.backgroundColor : ''};
  }
`;

const handleDoubleClick = (area, module, history, key, admin) => {
  if (!admin) {
    const urlNav = Utils.buildUrlNav(area, module, key);
    history.push(urlNav);
  }
};

const MainGridTableRowData = ({
  r,
  table,
  activeItem,
  handleRowClick,
  area,
  module,
  history,
  admin,
  templates,
}) => {
  const columnsDefaultData = {
    1: r.rowID,
    2: r.status,
    3: r.lastEdit.substr(0, 19).replace('T', ' '),
    4: r.creator,
    6: r.type,
    7: r.formLayoutName,
  };

  const isDataParseable = r.data && JSON.parse(r.data);

  let cells = [];
  let i = 0;

  if (table && table.columns) {
    cells = table.columns
      .sort((c1, c2) => c1.position - c2.position)
      .map(c => {
        let data = '';

        const parsedData = JSON.parse(r.data);
        const isDataHasProp =
          isDataParseable && parsedData.hasOwnProperty(c.key);

        if (isDataHasProp) {
          data = parsedData[c.key] ? parsedData[c.key].toString() : '';

          if (_.includes([FIELD_TYPE.datetime], typeMapper(c.type))) {
            data = getDateTimeFormatted(data);
          }
        }

        return {
          index: c.columnID ? c.columnID : ++i,
          data: (c.columnID && columnsDefaultData[c.columnID]) || data,
          position: c.position,
          style: _.find(r.cells, { key: c.key }),
        };
      });
  }

  // Disable actions for data with exceptional template
  const actionable = _.chain(templates)
    .map('name')
    .includes(r.formLayoutName)
    .value();

  return (
    <StyledRow
      id={MENU_IDS.MENU_TABLE_GRID_ROW}
      viewed={r.viewed}
      attributes={{
        className: activeItem[0] === r.key ? 'active' : '',
        onClick: () => handleRowClick(r.key, actionable),
        onDoubleClick: actionable
          ? () => handleDoubleClick(area, module, history, r.key, admin)
          : undefined,
      }}
      admin={admin}
      info={r.key}
      callbackOnShow={() => handleRowClick(r.key, actionable)}
    >
      {cells.map(c => (
        <StyledTableCell key={c.index} collapsing cellStyle={c.style}>
          {!r.viewed ? <b>{c.data}</b> : c.data}
        </StyledTableCell>
      ))}
    </StyledRow>
  );
};

const mapStateToProps = state => ({
  templates: getTemplates(state),
});

export default connect(
  mapStateToProps,
  null
)(MainGridTableRowData);
