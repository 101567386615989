const formToEvent = form => {
  const { formLayoutName, key, from, to, lastEdit } = form;

  return {
    title: `${formLayoutName} - ${key}`,
    start: new Date(from || lastEdit),
    end: new Date(to || lastEdit),
    key: form.key,
    form, // data to display
  };
};

export default formToEvent;
