import React, { useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import Utils from 'core/utils/utils';
import InputFile from './InputFile';
import ToastFileError from './components/ToastFileError';

const InputFileContainer = ({ fis, onChange, formData, disabled }) => {
  const { t } = useTranslation();
  const [fileName, setFileName] = useState('');

  const inputEl = useRef(null);

  const handleChangeFile = (e, field) => {
    e.preventDefault();

    const file = e.target.files[0];

    if (file) {
      const res = Utils.fileValidated(file);

      if (res && res.result) {
        onChange(e, field);
        setFileName(file.name);
      } else {
        toast.error(<ToastFileError invalidType={res.invalidType} />);
      }
    }
  };

  const handleResetFile = e => {
    inputEl.current.value = '';
    onChange(e, inputEl.current);
    setFileName('');
  };

  const handleSelectFile = e => {
    e.preventDefault();
    inputEl.current.click();
  };

  // XOR operation for toggle the required validation
  const toggleRequired = fis.required;

  return (
    <InputFile
      t={t}
      fis={fis}
      formData={formData}
      inputEl={inputEl}
      toggleRequired={toggleRequired}
      fileName={fileName}
      disabled={disabled}
      handleSelectFile={handleSelectFile}
      handleChangeFile={handleChangeFile}
      handleResetFile={handleResetFile}
    />
  );
};

export default InputFileContainer;
