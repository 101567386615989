import React from 'react';
import { Table } from 'semantic-ui-react';
import styled from 'styled-components';
import _ from 'lodash';

import Utils from 'core/utils/utils';
import { ThemedTable } from 'components';

import {
  MainGridTableRow,
  MainGridTableRowFooter,
  MainGridTableRowHeader,
  MainGridHeader,
} from '.';

const ScrollableTable = styled.div`
  &&& {
    max-width: 100%;
    min-height: 350px;
    overflow-x: scroll;
    margin-bottom: 1rem;
    scrollbar-width: thin; /*in Firefox*/

    .ui.table {
      background-color: ${({ theme: { color } }) => color.tableBg};
      color: ${({ theme: { color } }) => color.tableFg};
      border-color: ${({ theme: { color } }) => color.tableBorderColor};
    }
  }

  // in Chrome
  &&&::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  }
`;

const TableData = ({
  //state
  table,
  data,
  activeItem,
  activeColumn,
  direction,
  loading,
  // func
  handleSearchAllChange,
  handleSort,
  handleSearchFieldChange,
  handlePageChange,
  handleRowClick,
  // props
  area,
  module,
  history,
  dataType,
  dataKey,
}) => {
  const admin = dataType && dataKey ? true : false;

  // Calculate all infos for display
  const colSpanLength =
    table && !_.isEmpty(table.columns) ? table.columns.length : 0;

  const {
    allowSearchAll,
    currentPage,
    /*rowsPerPage,*/ rows,
    totalPage,
    totalRow,
  } = data;

  const tableColor = Utils.getColor(table.color);

  return (
    <>
      <MainGridHeader
        activeItem={activeItem}
        totalItems={totalRow}
        totalPages={totalPage}
        activePage={currentPage}
        handleSearchAllChange={handleSearchAllChange}
        area={area}
        module={module}
        loading={loading}
        showSearchAll={allowSearchAll}
        admin={admin}
      />
      <ScrollableTable>
        <ThemedTable
          sortable
          celled
          selectable
          structured
          tableColor={tableColor}
        >
          <Table.Header>
            <Table.Row textAlign="center">
              <MainGridTableRowHeader
                table={table}
                activeColumn={activeColumn}
                direction={direction}
                handleSort={handleSort}
                handleSearchFieldChange={handleSearchFieldChange}
              />
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <MainGridTableRow
              rows={rows}
              table={table}
              activeItem={activeItem}
              colSpanLength={colSpanLength}
              handleRowClick={handleRowClick}
              loading={loading}
              area={area}
              module={module}
              history={history}
              admin={admin}
            />
          </Table.Body>
        </ThemedTable>
      </ScrollableTable>
      <MainGridTableRowFooter
        totalItems={totalRow}
        totalPages={totalPage}
        activePage={currentPage}
        handlePageChange={handlePageChange}
        loading={loading}
      />
    </>
  );
};

export default TableData;
