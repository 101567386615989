import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import { Form } from 'semantic-ui-react';
import styled from 'styled-components';

import { FormDataCreators } from 'core/form-data/formDataReducer';

import { FormInput } from 'components';
import PopupTemplate from '../template/PopupTemplate';

import RegexUtils from 'core/utils/regex';

const Subtitle = styled.div`
  margin-bottom: 1em;
`;

const ReverseMargin = styled.div`
  margin-bottom: -1em;
`;

const PopupInputs = ({
  inputs,
  message,
  formData,
  updateFormData,
  ...other
}) => {
  const onChange = (e, field, pattern) => {
    if (!field) {
      field = e.target;
    }

    RegexUtils.updateFormWithMatchedPattern(e, field, pattern, updateFormData);
  };

  return (
    <PopupTemplate popupInputs {...other}>
      <Subtitle>{message}</Subtitle>
      <Form id="popupForm">
        {_.map(
          inputs,
          (
            {
              inputID,
              name,
              key,
              type,
              dropdown,
              required,
              isVisible,
              isReadOnly,
              pattern,
            },
            index
          ) => {
            const fis = {
              name,
              type,
              key,
              dropdown,
              required,
              pattern,
              isReadOnly,
            };

            return (
              <FormInput
                visible={isVisible}
                key={inputID}
                fis={fis}
                formData={formData}
                onChange={(e, d) => onChange(e, d, pattern)}
              />
            );
          }
        )}
      </Form>
      {_.isEmpty(inputs) && <ReverseMargin />}
    </PopupTemplate>
  );
};

export default connect(
  state => state.formData,
  dispatch => bindActionCreators(FormDataCreators, dispatch)
)(PopupInputs);
