import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { Button, Dropdown } from 'semantic-ui-react';
import _ from 'lodash';

import { FORM_LAYOUT_TYPE } from 'core/utils/constant';
import { usePrint } from 'core/hooks/usePrint';
import { getVisibleFormData } from 'core/form-data/formDataReducer';
import { getTemplates } from 'core/template/templateReducer';

import DocumentButton from './DocumentButton';

const PrintButton = ({
  // stores
  dispatch,
  formData,
  templates,
  // props
  documentID,
  printNow,
  selectedTemplate,
  disabled,
  onClick,
  onPrint,
  ...props
}) => {
  const printOptions = _.filter(templates, {
    type: FORM_LAYOUT_TYPE.print,
  }).map(t => ({
    value: t.key,
    text: t.name,
    key: t.key,
  }));

  printOptions.push({
    value: selectedTemplate.key,
    text: selectedTemplate.name,
    key: selectedTemplate.key,
  });

  const keyRef = useRef(selectedTemplate.key);
  const printData = useRef();

  const [templateKey, setTemplateKey] = useState(selectedTemplate.key);
  const [template, setTemplate] = useState();

  const [printPending] = usePrint(printData.current, template);

  // update form data
  useEffect(() => {
    printData.current = [{ documentID, ...formData }];
  }, [documentID, formData]);

  // template key was changed or print was started
  useEffect(() => {
    // template has changed -> trigger click
    if (!printNow && keyRef.current !== templateKey) {
      onClick();
    }
    // print was started -> trigger usePrint hook
    else if (printNow && keyRef.current === templateKey) {
      setTemplate(
        _.find(templates, {
          key: templateKey,
        })
      );
    }

    // update ref
    keyRef.current = templateKey;
  }, [printNow, templates, templateKey, onClick]);

  // deactivate print until next button click
  useEffect(() => {
    if (printPending) {
      setTemplate();
      onPrint();
    }
  }, [printPending, onPrint]);

  // change templat key from dropdown
  const changePrint = (e, { value }) => {
    setTemplateKey(value);
  };

  disabled = disabled || printPending;

  return (
    <Button.Group>
      <DocumentButton disabled={disabled} onClick={onClick} {...props} />
      <Dropdown
        as={Button}
        primary
        compact
        className="icon"
        value={templateKey}
        options={printOptions}
        disabled={disabled}
        trigger={<></>}
        onChange={changePrint}
        style={{ margin: '0.2em 0' }}
      />
    </Button.Group>
  );
};

PrintButton.propTypes = {};

PrintButton.defaultProps = {};

const mapStateToProps = state => ({
  formData: getVisibleFormData(state),
  templates: getTemplates(state),
});

export default connect(
  mapStateToProps,
  null
)(PrintButton);
