import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { DocumentButtonGroup, ThemedSegment } from 'components';

const DocumentButtonsSegment = ({
  clickables,
  viewersWarning,
  documentID,
  status,
  isValidForm,
  submitFunc,
  clearFunc,
  requiredFields,
  patternFields,
  filterSelection,
  printable,
  printed,
  print,
  selectedTemplate,
  exceptionURole,
  formData,
  hovering,
}) => (
  <ThemedSegment
    textAlign="center"
    transparent={hovering}
    style={hovering ? { marginTop: '-5px' } : undefined}
  >
    {clickables && (
      <DocumentButtonGroup
        documentID={documentID}
        status={status}
        clickables={clickables}
        viewersWarning={viewersWarning}
        isValidForm={isValidForm}
        submitFunc={submitFunc}
        clearFunc={clearFunc}
        formData={formData}
        requiredFields={requiredFields}
        patternFields={patternFields}
        filterSelection={filterSelection}
        printable={printable}
        printed={printed}
        print={print}
        selectedTemplate={selectedTemplate}
        exceptionURole={exceptionURole}
      />
    )}
  </ThemedSegment>
);

const mapStateToProps = state => {
  const { formData, printed } = state.formData;

  return {
    formData,
    printed,
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    null
  )(DocumentButtonsSegment)
);
