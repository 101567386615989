import React from 'react';
import { Step } from 'semantic-ui-react';
import styled from 'styled-components';

const StyledStepGroup = styled(Step.Group).attrs({
  size: 'small',
  unstackable: true,
})`
  &&& {
    margin-bottom: 0;
    overflow-x: auto;

    a.step {
      background-color: ${({ theme: { color } }) => color.stepBgDone};
      color: ${({ theme: { color } }) => color.stepFgDisabled};
      border-color: ${({ theme: { color } }) => color.borderColor};

      .title,
      .description {
        color: ${({ theme: { color } }) => color.stepFgDisabled};
      }

      &:hover {
        .icon {
          color: ${({ theme: { color } }) => color.stepIconActive};
        }
        .title {
          color: ${({ theme: { color } }) => color.primary};
        }
        .description {
          color: ${({ theme: { color } }) => color.tileFg};
        }
      }

      &::after {
        background-color: ${({ theme: { color } }) => color.stepBgDone};
        border-color: ${({ theme: { color } }) => color.borderColor};
      }
    }

    div.step {
      background-color: ${({ theme: { color } }) => color.stepBgDone};
      border-color: ${({ theme: { color } }) => color.borderColor};

      &.disabled,
      .title,
      .description {
        color: ${({ theme: { color } }) => color.stepFgDisabled};
      }

      &.active {
        background-color: ${({ theme: { color } }) => color.stepBgActive};

        .icon {
          color: ${({ theme: { color } }) => color.stepIconActive};
        }

        .title {
          color: ${({ theme: { color } }) => color.primary};
        }

        .description {
          color: ${({ theme: { color } }) => color.tileFg};
        }

        &::after {
          background-color: ${({ theme: { color } }) => color.stepBgActive};
        }
      }

      &::after {
        background-color: ${({ theme: { color } }) => color.stepBgDone};
        border-color: ${({ theme: { color } }) => color.borderColor};
      }
    }
  }
`;

const BatchSteps = ({ t, step, moveTo }) => (
  <StyledStepGroup>
    <Step
      active={step === 1 || undefined}
      onClick={step > 1 ? () => moveTo(1) : undefined}
      icon="search"
      title={t('batch|Search')}
      description={t('batch|Filter forms')}
    />
    <Step
      active={step === 2 || undefined}
      disabled={step < 2 || undefined}
      onClick={step > 2 ? () => moveTo(2) : undefined}
      icon="check circle outline"
      title={t('batch|Action')}
      description={t('batch|Choose action')}
    />
    <Step
      active={step === 3 || undefined}
      disabled={step < 3 || undefined}
      icon="flag checkered"
      title={t('batch|Perform')}
      description={t('batch|Finish process')}
    />
  </StyledStepGroup>
);

export default BatchSteps;
