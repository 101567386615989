import React, { useState } from 'react';

import TableData from '../main-grid/components/tableDataContainer';
import PageSegment from '../segments/PageSegment';

const AdminGrid = ({ table, dataType, dataKey, ...props }) => {
  const [activeItem, setActiveItem] = useState([]);

  return (
    <PageSegment {...props}>
      <TableData
        activeItem={activeItem}
        setActiveItem={setActiveItem}
        table={table}
        dataType={dataType}
        dataKey={dataKey}
      />
    </PageSegment>
  );
};

export default AdminGrid;
