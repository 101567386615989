import React, { useRef, useEffect } from 'react';
import { Polyline, withLeaflet } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet-polylinedecorator';

const PolylineDecorator = withLeaflet(props => {
  const polyRef = useRef();

  const patternArrowDefault = [
    {
      offset: 10,
      endOffset: 10,
      repeat: 100,
      symbol: L.Symbol.arrowHead({
        polygon: true,
        headAngle: 45,
        pixelSize: 6,
        pathOptions: {},
      }),
    },
  ];

  useEffect(() => {
    const polyline = polyRef.current.leafletElement; //get native Leaflet polyline

    L.polylineDecorator(polyline, {
      patterns: props.patterns || patternArrowDefault,
    }).addTo(props.mapRef.current.leafletElement);
  }, []);

  return <Polyline ref={polyRef} {...props} />;
});

export default PolylineDecorator;
