import React from 'react';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';
import { ButtonLink } from 'components';

const StyledIframe = styled.iframe`
  &&& {
    margin: -1px;
  }
`;

const handleClickLink = url => {
  window.open(url, '_blank');
};

const Embedded = ({ t, title, url, compact }) => (
  <>
    <StyledIframe
      title={title}
      src={url}
      frameBorder="0"
      width="100%"
      height={compact ? '450px' : '500px'}
      allowtransparency
    />
    <ButtonLink
      text={t('See full screen')}
      handleClickFunc={() => handleClickLink(url)}
    />
  </>
);

export default withTranslation()(Embedded);
