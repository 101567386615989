import React from 'react';
import { Segment } from 'semantic-ui-react';
import styled from 'styled-components';

const ThemedSegment = styled(
  ({ transparent, segmentColor, inline, active, ...props }) => (
    <Segment {...props} />
  )
)`
  &&& {
    display: ${({ inline }) => (inline ? 'flex' : 'block')};
    padding: ${({ inline }) => (inline ? '0.5em 0.5em' : '1em 1em')};
    background-color: ${({
      segmentColor,
      transparent,
      active,
      theme: { color },
    }) =>
      transparent
        ? color.bgTransparent
        : active
        ? color.tileBgActive
        : segmentColor || color.accentBg};
    color: ${({ theme: { color } }) => color.fg};
    box-shadow: ${({ theme: { color } }) => color.boxShadowSegment};

    label,
    .header {
      color: ${({ theme: { color } }) => color.fg};
    }

    i.icon {
      margin-right: ${({ inline }) => (inline ? '0.5rem' : 'default')};
    }
  }
`;

export default ThemedSegment;
