import { Form } from 'semantic-ui-react';
import styled from 'styled-components';

const ThemedCheckbox = styled(Form.Field)`
  &&& {
    label,
    input:focus:checked ~ label,
    input:checked ~ label {
      color: ${({ theme: { color } }) => color.fg} !important;
    }

    &.toggle label {
      &::before {
        background-color: ${({ theme: { color } }) => color.checkboxBg};
      }
      &:hover::before {
        background-color: ${({ theme: { color } }) => color.checkboxBgHover};
      }
    }
  }
`;

export default ThemedCheckbox;
