import React from 'react';
import styled from 'styled-components';

const StyledBackgroundDiv = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: ${({ theme: { color } }) => color.bgGeneral};
  background-size: cover;
  background-position: ${props => props.customBackgroundPosition};
`;

const BackgroundDiv = ({
  area,
  background: { customBackground, customBackgroundPosition },
}) => {
  const isAreaContentAvailable = area && area.areaContent;

  const getBackgroundImage = isAreaContentAvailable
    ? area.areaContent.background
      ? `url(${area.areaContent.background})`
      : undefined
    : customBackground
    ? "url('https://www.androidguys.com/wp-content/uploads/2016/01/nature-landscapes-mountains-monochrome-black-white-sky-clouds-wallpaper-1.jpg')"
    : undefined;

  return (
    <StyledBackgroundDiv
      style={{
        backgroundImage: getBackgroundImage,
      }}
    />
  );
};

export default BackgroundDiv;
