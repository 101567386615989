import React from 'react';
import { TextArea } from 'semantic-ui-react';
import styled from 'styled-components';

const ThemedTextArea = styled(({ inputRef, ...props }) => (
  <TextArea ref={inputRef} {...props} />
))`
  &&& {
    min-height: 77px;
    background-color: ${({ theme: { color } }) => color.inputBg};
    color: ${({ theme: { color } }) => color.inputFg};
    border-color: ${({ theme: { color } }) => color.borderColor};

    &:hover,
    &:focus {
      background-color: ${({ theme: { color } }) => color.inputBg};
      color: ${({ theme: { color } }) => color.inputFgHover};
    }
  }
`;

export default ThemedTextArea;
