import React, { lazy } from 'react';
import { Route, Switch } from 'react-router';
import _ from 'lodash';

import { PrivateRoute, Toast } from 'components';

import DispatchMapWrapper from './scenes/dashboard/scenes/dispatch/components/map/DispatchMapWrapper';

const Dashboard = lazy(() => import('./scenes/dashboard/dashboardContainer'));
const Public = lazy(() => import('./scenes/public/Public'));
const PublicForm = lazy(() =>
  import('./scenes/public/components/publicFormContainer')
);

const App = ({ areas }) => (
  <>
    <Switch>
      <Route
        exact
        path="/public/demand/:id(\d+)"
        render={() => <PublicForm />}
      />
      <PrivateRoute
        exact
        path="/filterselection"
        Component={<PublicForm filterSelection />}
      />
      {!_.isEmpty(areas) &&
        areas.map(a => (
          <Route
            key={a.key}
            path={`/${a.key}`}
            render={() => (
              <Switch>
                <Route
                  path={`/${a.key}/public`}
                  render={() => <Public area={a} />}
                />
                <PrivateRoute
                  exact
                  path={`/${a.key}/filterselection`}
                  Component={<PublicForm filterSelection />}
                />
                <PrivateRoute
                  exact
                  path={`/dispatch/map`}
                  render={() => <DispatchMapWrapper />}
                />
                <PrivateRoute
                  path={`/${a.key}/`}
                  Component={<Dashboard area={a} />}
                />
              </Switch>
            )}
          />
        ))}
      <Route path="/public" render={() => <Public />} />
      <PrivateRoute path="/" Component={<Dashboard />} />
    </Switch>
    <Toast />
  </>
);

export default App;
