import React from 'react';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Menu, Button } from 'semantic-ui-react';
import styled from 'styled-components';
import _ from 'lodash';

import Utils from 'core/utils/utils';
import { InputSearch } from 'components';

import { MainGridTotalPages, MainGridTotalForms } from './MainGridTotalItems';

const StyledMenuButton = styled(Button).attrs({
  icon: 'pencil',
  circular: true,
})`
  &&& {
    background-color: ${({ theme: { color } }) => color.primary};
    color: ${({ theme: { color } }) => color.white};

    &:hover {
      background-color: ${({ theme: { color } }) => color.primaryHover};
      color: ${({ theme: { color } }) => color.white};
    }

    @media (min-width: 767px) {
      .ui.menu:not(.vertical) .item > &.button {
        max-width: 200px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }
`;

const StyledMenu = styled(Menu).attrs({
  stackable: true,
  secondary: true,
})``;

const MenuItem = styled(Menu.Item)`
  &&& {
    min-width: 150px;
    color: ${({ theme: { color } }) => color.fg};
  }
`;

const MainGridHeader = ({
  t,
  activeItem,
  totalItems,
  totalPages,
  activePage,
  handleSearchAllChange,
  area,
  module,
  loading,
  showSearchAll,
  admin,
}) => (
  <StyledMenu stackable secondary>
    {showSearchAll && (
      <Menu.Item fitted="horizontally">
        <InputSearch
          name="search"
          icon="search"
          placeholder={t('common|Search...')}
          handleValue={handleSearchAllChange}
          loading={loading}
          clearable
        />
      </Menu.Item>
    )}
    <MenuItem>
      <MainGridTotalPages
        t={t}
        activePage={activePage}
        totalPages={totalPages}
        loading={loading}
      />
    </MenuItem>
    <MenuItem>
      <MainGridTotalForms t={t} totalItems={totalItems} loading={loading} />
    </MenuItem>
    {!admin && !_.isEmpty(activeItem) && (
      <Menu.Menu position="right">
        <Menu.Item fitted="vertically">
          {activeItem[1] ? (
            <Link to={Utils.buildUrlNav(area, module, activeItem[0])}>
              <StyledMenuButton content={t('common|Edit')} />
            </Link>
          ) : (
            <StyledMenuButton
              disabled={!activeItem[1]}
              content={t('common|Edit')}
            />
          )}
        </Menu.Item>
      </Menu.Menu>
    )}
  </StyledMenu>
);

export default withTranslation()(MainGridHeader);
