import React, { Fragment, useState, useRef } from 'react';
import { toast } from 'react-toastify';
import {
  DateInput,
  TimeInput,
  DateTimeInput,
  DatesRangeInput,
} from 'semantic-ui-calendar-react';
import _ from 'lodash';

import { getDateFormatString } from 'core/utils/date';
import { FIELD_TYPE, LIMIT_CHAR, INVALID_DATE } from 'core/utils/constant';
import { typeMapper } from 'core/utils/mapper';
import { StyledInput, ToastMessage, StyledFormField } from 'components';

import convertToStandardFormat from './helpers/convertToStandardFormat';
import convertToLocalFormat from './helpers/convertToLocalFormat';

const StyledDateInput = props => (
  <StyledInput component={DateInput} {...props} />
);

const StyledTimeInput = props => (
  <StyledInput component={TimeInput} {...props} />
);

const StyledDatesRangeInput = props => (
  <StyledInput component={DatesRangeInput} {...props} />
);

const StyledDateTimeInput = props => (
  <StyledInput component={DateTimeInput} {...props} />
);

const CalendarPicker = ({
  fis,
  visible = true,
  type,
  onChange,
  value,
  size,
  placeholder,
  ...props
}) => {
  const ref = useRef();
  const [multidates, setMultidates] = useState(
    _.includes([FIELD_TYPE.multidates], typeMapper(type)) && value
      ? value.split('|')
      : []
  );

  const [oldValue, setOldValue] = useState(value);

  if (_.isNil(visible)) return <Fragment />;

  const name = fis ? fis.key : undefined;
  const label = fis ? fis.name : undefined;
  const required = fis ? fis.required : undefined;
  const limit = fis && fis.pattern ? fis.pattern.split('|') : undefined; // format should be yyyy-MM-dd|yyyy-MM-dd

  const handleOnChange = (e, data) => {
    if (data.value.indexOf(' - ') >= 0) {
      // is date range
      const date1 = convertToStandardFormat(
        data.value.split(' - ')[0],
        data.dateFormat
      );
      const date2 = convertToStandardFormat(
        data.value.split(' - ')[1],
        data.dateFormat
      );

      if (date1 && date2) {
        data.value = `${date1}|${date2}`; // only update value if 2 dates are set
      }
    } else {
      data.value = convertToStandardFormat(data.value, data.dateFormat); // convert value to standard format
    }

    setOldValue(data.value); // save last change
    onChange(e, data); // update
  };

  const handleMultidateChange = (e, data) => {
    const formattedDate = convertToStandardFormat(data.value, data.dateFormat); // format date
    let newDates = [];

    if (formattedDate) {
      if (!multidates.includes(formattedDate)) {
        // is not has the current date then add it to dates
        newDates = [...multidates, formattedDate];
        newDates.sort(); // the standard format is yyyy-MM-dd so sort by string is enough
      } else {
        // already has then remove it from dates
        newDates = _.filter(multidates, d => d !== formattedDate);
      }
    }

    setMultidates(newDates);
    data.value = newDates.join('|'); // join data into 1
    setOldValue(data.value); // save last change
    onChange(e, data); // update change
  };

  const clearValueDateTime = (e, data) => {
    e.stopPropagation(); // stop propagation to avoid scroll to view
    setOldValue(data.value); // save last change
    onChange(e, data); // update
  };

  const handleScrollToView = event => {
    event.stopPropagation();

    if (type === FIELD_TYPE.datetime) {
      const { scrollY } = window;
      const center = window.innerHeight / 2;
      const positionInput = ref.current.getBoundingClientRect().top;

      //const stickyButtons = document.getElementsByClassName('sticky');
      //const stickyButtonsHeight = stickyButtons.length > 0 && scrollY + positionY > 100
      //  //? stickyButtons[0].clientHeight
      //  ? 100
      //  : 0;

      if (positionInput > center) {
        window.scrollTo({
          // minus the size of sticky button in simpleForm (constant = 100 for now)
          top: scrollY + positionInput - 100,
          //behavior: 'smooth', // smooth will make the picker stay appear when scroll and cause malfunctionning
        });
      }
    }
  };

  // Detect ad set date time format
  const dateFormat = getDateFormatString();
  const timeFormat = 'HH:mm';
  const dateTimeFormat = `${dateFormat} ${timeFormat}`;

  const commonProps = {
    name: name,
    label: label,
    required: required,
    onChange: handleOnChange,
    closable: true,
    clearable: true,
    className: 'column',
    iconPosition: 'right',
    autoComplete: 'off',
    hideMobileKeyboard: true,
    localization: localStorage.getItem('faster-lang'),
    size: size,
    pickerStyle: { height: '300px' },
    popupPosition: 'bottom right',
    ...props,
  };

  const additionalProps = {
    preserveViewMode: false,
  };

  const format = _.includes([FIELD_TYPE.datetime], type)
    ? dateTimeFormat
    : dateFormat;
  const min =
    limit && limit[0] ? convertToLocalFormat(limit[0], format) : undefined;
  const max =
    limit && limit[1] ? convertToLocalFormat(limit[1], format) : undefined;

  // value and limits are in the standard format so compare string is enough
  if (value && ((min && value < limit[0]) || (max && value > limit[1]))) {
    // set showing value to InvalidDate and toast message
    value = INVALID_DATE;
    if (oldValue !== value) {
      // toast only 1 noti since the last change user has made to this input
      toast.error(
        <ToastMessage
          error
          message={`The value of input '${label}' is not valid`}
        />
      );
      setOldValue(value);
    }
  }

  const limitProps = {
    minDate: min,
    maxDate: max,
    initialDate: min ? min : max ? max : undefined,
    // set intialDate when open calendar which have limit min|max because by default it will open today date which cause crash issue
  };

  let renderPicker = null;

  switch (type) {
    case FIELD_TYPE.time:
      renderPicker = (
        <StyledTimeInput {...commonProps} value={convertToLocalFormat(value)} />
      );
      break;
    case FIELD_TYPE.datetime:
      renderPicker = (
        <StyledDateTimeInput
          {...commonProps}
          {...additionalProps}
          {...limitProps}
          onClear={clearValueDateTime}
          dateFormat={dateFormat}
          value={convertToLocalFormat(value, dateTimeFormat)}
        />
      );
      break;
    case FIELD_TYPE.daterange: // date range
      renderPicker = (
        <StyledDatesRangeInput
          {...commonProps}
          {...limitProps}
          allowSameEndDate={true}
          dateFormat={dateFormat}
          maxLength={LIMIT_CHAR}
          value={convertToLocalFormat(value, dateFormat)}
        />
      );
      break;
    case FIELD_TYPE.multidates: // multiple date picker (more than 2 dates)
      renderPicker = (
        <StyledDateInput
          {...commonProps}
          {...additionalProps}
          {...limitProps}
          dateFormat={dateFormat}
          value={multidates
            .map(d => convertToLocalFormat(d, dateFormat))
            .join(', ')} // convert to local format before join
          onChange={handleMultidateChange}
          closable={false}
        />
      );
      break;
    case FIELD_TYPE.date:
    default:
      renderPicker = (
        <StyledDateInput
          {...commonProps}
          {...additionalProps}
          {...limitProps}
          dateFormat={dateFormat}
          value={convertToLocalFormat(value, dateFormat)}
        />
      );
      break;
  }

  return (
    <div
      ref={ref}
      onFocus={handleScrollToView}
      onClick={handleScrollToView}
      onKeyDown={e => {
        if (e.key !== 'Tab') {
          e.preventDefault(); // block others keys if this is not Tab key
        }
      }}
      className="field column"
    >
      <StyledFormField visible={visible}>{renderPicker}</StyledFormField>
    </div>
  );
};

export default CalendarPicker;
