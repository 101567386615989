import React from 'react';
import { Search } from 'semantic-ui-react';
import styled from 'styled-components';
import { StyledInput } from 'components';

const ThemedSearch = styled(({ ...props }) => (
  <StyledInput as={Search} showNoResults={false} fluid {...props} />
))`
  &&&& {
    > .results {
      margin: 0;
      background: ${({ theme: { color } }) => color.dropdownBg};
      border: none;
      min-width: 18em;
      overflow-y: auto;
      max-height: 200px;

      > .message.empty {
        background: #fff;
      }
    }

    .result {
      min-height: 3.1em;

      background: ${({ theme: { color } }) => color.dropdownBg};
      color: ${({ theme: { color } }) => color.dropdownFg};
      border-color: ${({ theme: { color } }) => color.borderColor};

      &:hover {
        background-color: ${({ theme: { color } }) => color.dropdownBgHover};
        color: ${({ theme: { color } }) => color.dropdownFgHover};
      }
    }
  }
`;

export default ThemedSearch;

// use like this
//{
//  editable && (
//    <ThemedSearch
//      input={{ icon: undefined }}
//      loading={false}
//      required={required}
//      open={show}
//      value={inputValue}
//      results={results}
//      resultRenderer={resultRenderer}
//      onResultSelect={handleResultSelect}
//      onSearchChange={handleResultSelect}
//      onMouseDown={handleFocus}
//      onBlur={handleOnBlur}
//    />
//  )
//}

// improve by making clearable etc.
