export const SYSTEM_FIELDS = {
  area: 'AREA',
  formid: 'FORMID',
  formlayoutname: 'FORMLAYOUTNAME',
  formlayouttype: 'FORMLAYOUTTYPE',
  lastedit: 'LASTEDIT',
  status: 'STATUS',
  user: 'USER',
};

export const FIELD_ACTION = {
  initialize: 'INITIALIZE',
  fill: 'FILL',
  show: 'SHOW',
  focusIn: 'FOCUSIN',
  focusOut: 'FOCUSOUT',
  visible: 'VISIBLE',
};

export const FIELD_ACTION_TAG = {
  field: 'FIELD',
  section: 'SECTION',
  category: 'CATEGORY',
};

export const FIELD_TYPE = {
  // values use in backend, DB and frontend
  address: 'ADDRESS',
  autocomplete: 'AUTOCOMPLETE',
  multidates: 'MULTIDATES',
  checkbox: 'CHECKBOX',
  combolist: 'COMBOLIST',
  date: 'DATE',
  datetime: 'DATETIME',
  decimal: 'DECIMAL',
  daterange: 'DATERANGE',
  number: 'NUMBER',
  photo: 'PHOTO',
  file: 'FILE',
  text: 'TEXT',
  textarea: 'TEXTAREA',
  time: 'TIME',
  editabledropdown: 'EDITABLEDROPDOWN',
  icon: 'ICON',
  timerange: 'TIMERANGE',
  searchselect: 'SEARCHSELECT',
  searcheditselect: 'SEARCHEDITSELECT',
};

export const CONTENT_TYPE = {
  table: 'TABLE',
  iframe: 'IFRAME',
  trackingByAddress: 'TRACKINGBYADDRESS',
  title: 'TITLE',
  text: 'TEXT',
  agenda: 'AGENDA',
  history: 'HISTORY',
  templateHeader: 'TEMPLATEHEADER',
  image: 'IMAGE',
};

export const OPERATOR = {
  contains: 'CONTAINS',
  notContains: 'NOTCONTAINS',
  equals: 'EQUALS',
  notEquals: 'NOTEQUALS',
  between: 'BETWEEN', // Value must be of type "from|to"
  startsWith: 'STARTSWITH',
  endsWith: 'ENDSWITH',
  in: 'IN',
};

export const FORM_LAYOUT_TYPE = {
  client: 'CLIENT',
  filter: 'FILTER',
  screen: 'SCREEN',
  print: 'PRINT',
};

export const FIELD_KEY = {
  client: 'CLIENT.Code',
};

export const BUTTON_TYPE = {
  link: 'LINK',
  print: 'PRINT',
  popup: 'POPUP',
  reset: 'RESET',
  status: 'STATUS',
};

export const MODAL_TYPE = {
  confirm: 'Confirm',
  empty: 'Empty',
  warning: 'Warning',
};

export const ACTION_TYPE = {
  status: 'STATUS',
  print: 'PRINT',
  popup: 'POPUP',
  reset: 'RESET',
  link: 'LINK',
};

export const DEFAULT_OPTION = {
  key: '',
  value: '',
  text: '',
  items: null,
};

export const POPUP_TAB_NAME = {
  general: 'GENERAL',
  access: 'ACCESS',
  modal: 'MODAL',
};

export const ACCESS_RULE_TYPE = {
  role: 'ROLE',
  user: 'USER',
};

export const MENU_IDS = {
  MENU_TABLE_GRID_ROW: 'MENU_TABLE_GRID_ROW',
  MENU_TIMELINE_ROUND: 'MENU_TIMELINE_ROUND',
  MENU_TRANSPORT: 'MENU_TRANSPORT',
};

export const TRANSPORT_STATUS = {
  DONE: 'Done',
  IN_DISPATCHING: 'In Dispatching',
  TO_DISPATCH: 'To Dispatch',
  PICKUP_DELIVERY: 'Pickup Delivery',
};

export const ACCESS_RULE_PERMISSION = {
  create: 'CREATE',
  write: 'WRITE',
  read: 'READ',
};

export const ACCESS_RULE_ALLOW = {
  yes: 'YES',
  no: 'NO',
};

export const THEME = {
  light: 'LIGHT',
  dark: 'DARK',
};

export const LIMIT_CHAR = 255;

export const COLOR = {
  red: 'red',
  orange: 'orange',
  yellow: 'yellow',
  olive: 'olive',
  green: 'green',
  teal: 'teal',
  blue: 'blue',
  violet: 'violet',
  purple: 'purple',
  pink: 'pink',
  brown: 'brown',
  grey: 'grey',
  black: 'black',
};

export const LOCALES = {
  en: 'EN',
  fr: 'FR',
};

export const INVALID_DATE = 'INVALID_DATE';

export const DRAFT_STATUS = 'Draft';

export const VALID_IMAGE_EXTENSION = {
  jpg: 'jpg',
  png: 'png',
  jpeg: 'jpgeg',
  gif: 'gif',
  svg: 'svg',
  bmp: 'bmp',
  tiff: 'tiff',
};

export const FILE_SIZE_LIMIT = 5; // 5MB

export const INVALID_FILE_TYPE = {
  invalidFileExtension: 'INVALID_FILE_EXTENSION',
  invalidSize: 'INVALID_SIZE',
  invalidImageExtension: 'INVALID_IMAGE_EXTENSION',
};

export const LOGO_URL = {
  medium: '/Stockage/Image/logo.png',
  small: '/Stockage/Image/logo-small.png',
};
