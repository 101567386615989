import React from 'react';
import { Icon } from 'semantic-ui-react';

import Utils from 'core/utils/utils';
import InputFileButton from './components/InputFileButton';
import { InputFileHidden } from './components/InputFileHidden';
import { StyledInput } from '../..';

const InputFile = ({
  t,
  fis,
  formData,
  inputEl,
  toggleRequired,
  handleSelectFile,
  handleChangeFile,
  handleResetFile,
  fileName,
  disabled,
}) => {
  let icon = undefined;

  if ((formData && formData[fis.key]) || fileName) {
    icon = <Icon name="delete" link onClick={handleResetFile} />;
  }

  const openFile = () => {
    window.open(formData[fis.key], '_blank');
  };

  const isFileStored =
    formData &&
    formData[fis.key] &&
    !formData[`${fis.key}_fileName`] &&
    !fileName;
  const fileNameStored = isFileStored
    ? Utils.getFullNameFromUrl(formData[fis.key])
    : undefined; // retrieve only the name

  return (
    <>
      <label>{fis.name}</label>
      <StyledInput
        action={
          <InputFileButton
            content={t('common|Upload')}
            icon="upload"
            onClick={handleSelectFile}
            disabled={disabled}
          />
        }
        fluid
        actionPosition="left"
        value={isFileStored ? fileNameStored : fileName || ''}
        readOnly
        icon={icon}
        disabled={disabled}
        onClick={isFileStored ? openFile : undefined}
      />
      <InputFileHidden
        ref={inputEl}
        name={fis.key}
        onChange={handleChangeFile}
        required={toggleRequired}
      />
    </>
  );
};

export default InputFile;
