import React from 'react';
import { Checkbox } from 'semantic-ui-react';
import _ from 'lodash';

import Utils from 'core/utils/utils';
import { FIELD_TYPE } from 'core/utils/constant';
import {
  CalendarPicker,
  FasterDropdown,
  InputFile,
  InputFileImage,
  StyledFormField,
  StyledInput,
} from 'components';

import FormFieldInput from './field/FormFieldInput';
import FormFieldTextarea from './field/FormFieldTextarea';
import AutocompleteInput from './autocomplete/AutocompleteInput';
// import AddressAutocompleteInput from './autocomplete/AddressAutocompleteInput';

const FormInput = ({
  fis,
  visible,
  onChange,
  onFocusChange,
  onAutoComplete,
  formData,
  disabled,
  visibleCategories = undefined,
}) => {
  const fieldType = fis.type;

  const {
    decimal,
    number,
    text,
    checkbox,
    combolist,
    file,
    photo,
    textarea,
    time,
    date,
    datetime,
    daterange,
    multidates,
    autocomplete,
    address,
    editabledropdown,
    searchselect,
    searcheditselect,
  } = FIELD_TYPE;

  let dropdownOptions = (fis.dropdown && fis.dropdown.options) || [];
  const dropdownHasCategory =
    dropdownOptions.length > 0 &&
    dropdownOptions.some(opt => opt.items !== null);

  if (
    [combolist, editabledropdown, searchselect, searcheditselect].includes(
      fieldType
    ) &&
    visibleCategories &&
    dropdownHasCategory
  ) {
    // filter categories base on theirs visibility
    const stateCategories = visibleCategories.find(f => f.name === fis.key)
      .categories;
    dropdownOptions = dropdownOptions.filter(
      cat =>
        cat.items !== null &&
        stateCategories.find(c => c.name === cat.key).value
    );
  }

  const onFocusInOut = {
    //onFocus: e => onFocusChange(e, fis.key, fieldType),
    onBlur: onFocusChange
      ? e => onFocusChange(e, fis.key, fieldType)
      : undefined,
  };

  const isDisabled = disabled || fis.isReadOnly;

  const commonProps = {
    className: 'column',
    required: fis.required,
    visible: visible,
    ...onFocusInOut,
    disabled: isDisabled,
  };

  return (
    <>
      {[decimal, number, text].includes(fieldType) && (
        <FormFieldInput
          {...commonProps}
          control={StyledInput}
          label={fis.name}
          type={fieldType}
          step={
            fieldType === decimal
              ? 'any'
              : fieldType === number
              ? '1'
              : undefined
          }
          name={fis.key}
          pattern={fis.pattern}
          onChange={onChange}
          value={formData && formData[fis.key] ? formData[fis.key] : ''}
        />
      )}

      {fieldType === checkbox && !_.isNil(visible) && (
        <StyledFormField
          {...commonProps} // onFocus on checkbox will block the onChange event, need to investigate this problem when process onFocus
          control={Checkbox}
          label={fis.name}
          name={fis.key}
          onChange={onChange}
          checked={formData && Utils.getBoxChecked(formData[fis.key])}
        />
      )}

      {_.includes(
        [combolist, editabledropdown, searchselect, searcheditselect],
        fieldType
      ) &&
        !_.isNil(visible) && (
          <StyledFormField name={fis.key} {...commonProps}>
            <label>{fis.name}</label>
            <FasterDropdown
              name={fis.key}
              value={formData && formData[fis.key] ? formData[fis.key] : ''}
              options={dropdownOptions}
              required={fis.required}
              categorize={dropdownHasCategory}
              pattern={
                _.includes([editabledropdown, searcheditselect], fieldType)
                  ? fis.pattern
                  : undefined
              }
              editable={_.includes(
                [editabledropdown, searcheditselect],
                fieldType
              )}
              searchable={_.includes(
                [searchselect, searcheditselect],
                fieldType
              )}
              onChange={onChange}
            />
          </StyledFormField>
        )}

      {fieldType === file && !_.isNil(visible) && (
        <StyledFormField {...commonProps}>
          <InputFile
            fis={fis}
            formData={formData}
            onChange={onChange}
            disabled={isDisabled}
          />
        </StyledFormField>
      )}

      {fieldType === photo && !_.isNil(visible) && (
        <StyledFormField {...commonProps}>
          <InputFileImage
            fis={fis}
            formData={formData}
            onChange={onChange}
            disabled={isDisabled}
          />
        </StyledFormField>
      )}

      {fieldType === textarea && (
        <FormFieldTextarea
          {...commonProps}
          field={fis}
          onChange={onChange}
          value={formData ? formData[fis.key] : undefined}
        />
      )}

      {[time, date, datetime, daterange, multidates].includes(fieldType) && (
        <CalendarPicker
          type={fieldType}
          fis={fis}
          visible={visible}
          onChange={onChange}
          value={formData && formData[fis.key] ? formData[fis.key] : ''}
          disabled={isDisabled}
          {...onFocusInOut}
        />
      )}

      {fieldType === autocomplete && !_.isNil(visible) && (
        <StyledFormField name={fis.key} {...commonProps}>
          <label>{fis.name}</label>
          <AutocompleteInput
            name={fis.key}
            fieldId={fis.fieldID}
            value={formData && formData[fis.key] ? formData[fis.key] : ''}
            pattern={fis.pattern}
            required={fis.required}
            onChange={onChange}
            onAutoComplete={onAutoComplete}
            disabled={isDisabled}
          />
        </StyledFormField>
      )}

      {fieldType === address && !_.isNil(visible) && (
        <StyledFormField name={fis.key} {...commonProps}>
          <label>{fis.name}</label>
          <AutocompleteInput
            type="address"
            name={fis.key}
            fieldId={fis.fieldID}
            value={formData && formData[fis.key] ? formData[fis.key] : ''}
            onChange={onChange}
            onAutoComplete={onAutoComplete}
            disabled={isDisabled}
          />
        </StyledFormField>
      )}
    </>
  );
};

export default FormInput;
