import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { useApi } from 'api/useApi';
import tableApi from 'api/table/tableApi';
import { getCurrentUser } from 'core/auth/authReducer';
import { ContextMenuCreators } from 'core/context-menu/contextMenuReducer';
import { getTemplates } from 'core/template/templateReducer';
import Utils from 'core/utils/utils';
import { MENU_IDS } from 'core/utils/constant';
import { Spinner } from 'components';

import MainGrid from './MainGrid';

const MainGridContainer = props => {
  const { t, history, addMenu, area, module, tableKey } = props;

  const [args] = useState([tableKey]);
  const [activeItem, setActiveItem] = useState([]);

  const [fetchedTable, pendingTable] = useApi(tableApi.getTable, args);

  useEffect(() => {
    const menuItemOnClick = (action, info) => {
      const urlNav = Utils.buildUrlNav(area, module, info);

      if (action === 'Open') {
        // Open in same tab
        history.push(urlNav);
      } else if (action === 'OpenInNewTab') {
        // Open in new tab
        window.open(urlNav, '_blank');
      }
    };

    const menuTableGridRow = {
      id: MENU_IDS.MENU_TABLE_GRID_ROW,
      items: [
        {
          disabled: !activeItem[1],
          data: { action: 'Open' },
          title: t('common|Open'),
          onClick: (action, info) => menuItemOnClick(action, info),
        },
        {
          disabled: !activeItem[1],
          data: { action: 'OpenInNewTab' },
          title: t('common|Open in new tab'),
          onClick: (action, info) => menuItemOnClick(action, info),
        },
      ],
    };

    // setItems(menuItems);
    addMenu(menuTableGridRow);
  }, [addMenu, t, area, history, module, activeItem]);

  return pendingTable ? (
    <Spinner />
  ) : (
    <MainGrid
      table={fetchedTable.table}
      activeItem={activeItem}
      setActiveItem={setActiveItem}
      {...props}
    />
  );
};

const mapStateToProps = state => {
  const { token, selectedFilters } = getCurrentUser(state);

  return {
    token,
    selectedFilters,
    templates: getTemplates(state),
  };
};

const mapDispatchToProps = dispatch => ({
  // setItems: items => dispatch(ContextMenuCreators.setItems(items)),
  addMenu: menu => dispatch(ContextMenuCreators.addMenu(menu)),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withTranslation()(MainGridContainer))
);
