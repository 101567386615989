import React from 'react';

import { Table } from 'semantic-ui-react';
import styled from 'styled-components';

const ThemedTable = styled(({ tableColor, ...props }) => (
  <Table {...props} />
)).attrs({
  celled: true,
  sortable: true,
  selectable: true,
  structured: true,
})`
  &&&&& {
    color: ${({ theme: { color } }) => color.fg};
    background-color: ${({ theme: { color } }) => color.tableBg};
    border-color: ${({ theme: { color } }) => color.borderColor}};
    ${({ tableColor }) =>
      tableColor && `border-top: .2em solid ${tableColor} !important;`}

    &.ui.sortable.table thead th.sorted:after {
      display: none;
    }

    th {
      color: ${({ theme: { color } }) => color.fg};
      background-color: ${({ theme: { color } }) => color.tableHeaderBg};
      border-color: ${({ theme: { color } }) => color.borderPrincipal};
      &:hover {
        background-color: ${({ theme: { color } }) => color.tableHeaderBgHover};
        color: ${({ theme: { color } }) => color.tableFg};
      }
      &.sorted,
      &.sorted:hover {
        background-color: ${({ theme: { color } }) => color.tableHeaderBgFocus};
      }
      &.sorted > div:first-child:after {
        display: inline-block;
      }
      & > div:first-child:after {
        display: none;
        font-style: normal;
        font-weight: 400;
        text-decoration: inherit;
        height: 1em;
        width: auto;
        opacity: 0.8;
        margin: 0 0 0 0.5em;
        font-family: Icons;
        content: '';
      }
      &.ascending > div:first-child:after {
        content: '\\F0D8';
      }
      &.descending > div:first-child:after {
        content: '\\F0D7';
      }
    }

    &&&& tr {
      border-color: ${({ theme: { color } }) => color.borderPrincipal};
      &:hover {
        color: ${({ theme: { color } }) => color.tableFg} !important;
        background: ${({ theme: { color } }) =>
          color.tableItemBgHover} !important;
      }
      &.active,
      &.active:hover {
        color: ${({ theme: { color } }) => color.tableFg} !important;
        background: ${({ theme: { color } }) =>
          color.tableItemBgActive} !important;
      }
      &.positive,
      &.warning {
        background: ${({ theme: { color } }) => color.rowBg} !important;
      }
    }

    td {
      border-color: ${({ theme: { color } }) => color.borderPrincipal};
      &.positive {
        color: ${({ theme: { color } }) => color.positiveFg} !important;
        background: ${({ theme: { color } }) => color.positiveBg} !important;
      }
      &.warning {
        color: ${({ theme: { color } }) => color.warningFg} !important;
        background: ${({ theme: { color } }) => color.warningBg} !important;
      }
      &.error {
        color: ${({ theme: { color } }) => color.errorFg} !important;
        background: ${({ theme: { color } }) => color.errorBg} !important;
      }
    }
  }
`;

export default ThemedTable;
