import React from 'react';
import { Translation } from 'react-i18next';
import { toast } from 'react-toastify';
import _ from 'lodash';

import Utils from 'core/utils/utils';
import { ToastMessage } from 'components';

export const VALID_FUNC_TYPE = {
  charLimit: 'CHAR_LIMIT',
  requiredFields: 'REQUIRED_FIELDS',
  patternFields: 'PATTERN_FIELDS',
};

// check required inputs in document
export const isValidDocument = (
  formData,
  requiredFields,
  patternFields,
  fields
) => {
  const boolArray = [];

  // verify required fields first
  const validRequiredFields = checkIsValidRequiredFields(
    requiredFields,
    formData,
    'value'
  );
  boolArray.push(validRequiredFields);

  if (validRequiredFields) {
    // verify limit of char
    const validCharLimit = checkIsValidCharLimit(fields, formData);
    boolArray.push(validCharLimit);

    if (validCharLimit) {
      // verify pattern fields
      const validPatternFields = checkIsValidPatternFields(
        patternFields,
        formData,
        'value'
      );
      boolArray.push(validPatternFields);
    }
  }

  return Utils.reduceAndBoolArray(boolArray);
};

// check required inputs in popup
export const isValidPopup = async (popupAction, callback) => {
  const { inputs } = popupAction;

  let validRequiredFields = false;
  let validPatternFields = false;

  await callback(currentFormData => {
    validRequiredFields = checkIsValidRequiredFields(
      inputs,
      currentFormData,
      'required'
    );

    // is valid required fields then verify pattern fields
    if (validRequiredFields) {
      validPatternFields = checkIsValidPatternFields(
        inputs,
        currentFormData,
        'pattern'
      );
    }

    return currentFormData;
  });

  return validRequiredFields && validPatternFields;
};

// ensure required inputs have values
export const checkIsValidRequiredFields = (fields, formData, fieldProp) =>
  checkIsValid(
    fields,
    field =>
      field[fieldProp] && (!formData[field.key] || formData[field.key] === ''),
    VALID_FUNC_TYPE.requiredFields
  );

export const checkIsValidPatternFields = (fields, formData, fieldProp) =>
  checkIsValid(
    fields,
    field =>
      field[fieldProp] &&
      !RegExp(`^${field.pattern}$`).test(formData[field.key]),
    VALID_FUNC_TYPE.patternFields
  );

export const checkIsValidCharLimit = (fields, formData) =>
  checkIsValid(
    formData,
    value =>
      value &&
      value.length > 255 &&
      !(value.startsWith('data:') && value.includes(';base64,')),
    VALID_FUNC_TYPE.charLimit,
    fields
  );

export const checkIsValid = (array, predicate, typeValid, fields) => {
  const firstInvalidFound =
    typeValid === VALID_FUNC_TYPE.charLimit
      ? _.findKey(array, predicate)
      : _.find(array, predicate);

  if (firstInvalidFound) {
    if (typeValid === VALID_FUNC_TYPE.charLimit) {
      const invalidField = _.find(fields, { key: firstInvalidFound });

      toast.error(
        <Translation>
          {t => (
            <ToastMessage
              error
              message={`${t("Field '")}${invalidField.name}${t(
                "' is not valid"
              )}`}
            />
          )}
        </Translation>
      );
    } else if (typeValid === VALID_FUNC_TYPE.patternFields) {
      // TODO use pattern's description to toast for user
      toast.error(
        <Translation>
          {t => (
            <ToastMessage
              error
              message={`${t("Field '")}${firstInvalidFound.name}${t(
                "' is not valid"
              )} '${firstInvalidFound.pattern}'`}
            />
          )}
        </Translation>
      );
    } else if (typeValid === VALID_FUNC_TYPE.requiredFields) {
      toast.error(
        <Translation>
          {t => (
            <ToastMessage
              error
              message={`${t("Field '")}${firstInvalidFound.name}${t(
                "' is required"
              )}`}
            />
          )}
        </Translation>
      );
    }
  }

  return firstInvalidFound ? false : true;
};
