import { Popup } from 'semantic-ui-react';
import styled from 'styled-components';

const ThemedPopup = styled(Popup)`
  &&& {
    &,
    &::before {
      background-color: ${({ theme: { color } }) => color.popupBg};
      border-color: ${({ theme: { color } }) => color.popupBorderColor};
    }

    &.ui.bottom.right.popup::before {
      box-shadow: -1px -1px 0 0 ${({ theme: { color } }) => color.popupArrowBoxShadow};
    }

    color: ${({ theme: { color } }) => color.fg};
  }
`;

export default ThemedPopup;
