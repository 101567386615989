import React from 'react';

import styled from 'styled-components';
import { Item, Button, Icon, Grid, Label } from 'semantic-ui-react';
import { STOP_STATUS } from '../../../../api/stop/stopsMock';

const Container = styled(Item)`
  &&& {
    padding: 1em 1em;
    border-radius: 0.28571429rem;
    border: ${({ theme: { color } }) => color.border} !important;
    cursor: pointer;
    background-color: ${({ theme: { color } }) => color.bgGeneral} !important;
  }
`;

const LocationName = styled.h4`
  margin-bottom: 0;
`;

const StyledMetaItem = styled(Item.Meta)`
  & {
    color: ${({ theme: { color } }) => color.popupFg} !important;
  }
`;

const StyledMetadata = styled.div`
  font-weight: bold;
  color: ${({ theme: { color } }) => color.fg} !important;
  font-style: italic;
`;

const StyledButtonLink = styled(Button)`
  &&&&& {
    background: transparent;
    color: ${({ theme: { color } }) => color.buttonFg} !important;
  }

  &&&&&:hover {
    background-color: ${({ theme: { color } }) =>
      color.popupBgHover} !important;
  }
`;

const EditToolsContainer = styled.div`
  float: right;
`;

const StyledButton = ({ name, handleClick }) => (
  <Button icon circular as={StyledButtonLink} onClick={handleClick}>
    <Icon name={name} />
  </Button>
);

const EditTools = ({ handleEditMode }) => (
  <EditToolsContainer>
    <Grid.Row>
      <StyledButton name="pencil" handleClick={handleEditMode} />
    </Grid.Row>
    <Grid.Row>
      <StyledButton name="trash alternate" />
    </Grid.Row>
  </EditToolsContainer>
);

const StopListItem = ({ info, handleClick, handleEditMode }) => {
  const { name, address, client, time, status } = info;
  const { name: clientName, phone } = client;

  return (
    <Container onClick={handleClick}>
      <Item.Content>
        <Grid columns={4}>
          <Grid.Column width={1}>
            <Icon name="check" />
          </Grid.Column>
          <Grid.Column width={7}>
            <LocationName>{name}</LocationName>
            <StyledMetaItem>{address}</StyledMetaItem>
            <StyledMetadata>{`${clientName} - ${phone}`}</StyledMetadata>
          </Grid.Column>
          <Grid.Column width={5}>
            <LocationName>{time}</LocationName>
            <Item.Meta>
              <Label color={status === STOP_STATUS.SCHEDULED ? 'green' : 'red'}>
                {status}
              </Label>
            </Item.Meta>
          </Grid.Column>
          <Grid.Column width={2}>
            <EditTools handleEditMode={handleEditMode} />
          </Grid.Column>
        </Grid>
      </Item.Content>
    </Container>
  );
};

export default StopListItem;
